<template>
  <div class="headerAktualne grid">
    <div class="headerLogo">
        <div class="headerLogoTitle" @click="update">MeteoBB</div>
        <div class="headerLokacie">
            <img src="../assets/ikony/white/location.png" alt="" class="lokacieImg">
            <span v-for="(_lokalita, index) in lokality" :key="_lokalita.id" class="lokacia" :class="{selected:index==aktualnalokalita}" @click="changeLokalita(index)">{{_lokalita.label}}</span>
        </div>
    </div>
    <div v-if="data!=null" class="data">
        <img class="headerIkona" :src="pocasieObrazok" alt="">
        <div class="headerInfo">
            <div class="headerInfoStav">{{data.aktualne}}</div>
            <div class="headerInfoTeplota">{{data.teplota}}</div>
            <div class="headerInfoPodrobne">
                <img src="../assets/ikony/white/tlak.png" alt="">
                <p>{{data.tlak}}</p>
                <img style="margin-left: 15px;" src="../assets/ikony/white/vlhkost.png" alt="">
                <p>{{data.vlhkost}}</p>
            </div>
        </div>
    </div>
    <div v-else-if="online" class="loaderHeader">
        <div class="loaderItem">Načítavam údaje</div>
        <Loader class="loaderItem" />
    </div>
    <div v-else class="loaderHeader">
        <div class="loaderItem">Stanica {{lokality[aktualnalokalita].label}} OFFLINE</div>
        <img src="../assets/ikony/black/offline.png"/>
    </div>
</div>
</template>

<script>
import Loader from "./Loader.vue"
export default {
    components: {Loader},
    emits: ["updateInterface","loaded","lokalitaChanged","update"],
    props: ["lokalita"],
    data(){
        return {
            data: null,
            pocasieObrazok: "",
            online:1,
            lokality: [
                {
                    label: "Centrum",
                    id: "centrum"
                },{
                    label: "Sásová",
                    id: "sasova"
                }
            ],
            aktualnalokalita: this.lokalita
        }
    },
    mounted(){
        this.emitInterface()
        //console.log(this.lokalita)
    },
    methods:{
        async updateMe(lokalita){
            this.online=1;
            const data = await fetch("PHP/loadHeader.php?lokalita="+lokalita);
            const dataJson = await data.json(); 
            
            this.online=dataJson.online;
            if(!this.online){
                this.data=null;
                this.$emit("loaded","aktual")
                return;
            }
            this.pocasieObrazok=require("../assets/ikonyPocasie/"+dataJson.ikona)
            this.data=dataJson;
            
            this.$emit("loaded","aktual")
        },
        emitInterface(){
            this.$emit("updateInterface",{id: "header",updateInterface: this.updateMe, lokalitaChanged: this.lokalitaChanged})
        },

        changeLokalita(id){
            
            this.$emit("lokalitaChanged",id)
        },

        lokalitaChanged(id){
            this.aktualnalokalita=id
        },

        update(){
            this.$emit("update")
        }
    }
}
</script>

<style>
    .headerAktualne{
        font-size: 2.5vh;
        transform: translateZ(0.5px) scale(0.75);
        transform-origin: top;
    }

    @media screen and (max-height: 375px) {
        .headerAktualne{
            font-size: 9px;
        }
    }

    .headerAktualne .data{
        grid-column: span 12;
        justify-self: center;
    }

    
    .loaderHeader{
        font-size: 0.8em;
        grid-column: span 12;
        display: flex;
        justify-content: center;
        margin-top: 5vh;
    }

    .loaderHeader img{
        height: 1.5em;
        align-self: center;
        margin: 3px;
    }

    .loaderHeader .loaderItem{
        align-self: center;
        margin: 3px;
        font-size: 1.2em;
        font-weight: 300;
    }


    .headerLogo{
        grid-column: span 12;
        justify-self: center;
        padding-top: 0.1em;
        color: white;
        text-align: center;
        align-self: end;
    }

    .headerLogoTitle{
        font-size: 3.5em;
        font-weight: 900;
        cursor: pointer;
    }

    .headerLokacie{
        padding-bottom: 1em;
        font-weight: 100;
        font-size: 0.8em;
    }

    .lokacieImg{
        height: 1.3em;
        vertical-align: middle;
        margin: 5px
    }

    .lokacia{
        padding-bottom: 3px;
        margin: 0 5px;
        cursor: pointer;
    }
    @media(hover: hover) and (pointer: fine) {
        .lokacia:hover{
            border-bottom: 1px solid rgb(216, 216, 216);
        }
    }
    .headerLokacie .selected{
        border-bottom: 1px solid white;
        font-weight: 300;
    }

    .headerIkona{
        max-height: 20vh;
        min-height: 75px;
        vertical-align: middle;
    }
    .headerInfo{
        margin-left: 10px;
        display: inline-block;
        vertical-align: middle;
    }

    .headerInfoStav{
        font-size: 1.2em;
        font-weight: 300;
        line-height: 1em;
    }

    .headerInfoTeplota{
        font-size: 3.2em;
        font-weight: 900;
        line-height: 1em;
        padding: 5px 0;
    }

    .headerInfoPodrobne{
        font-size: 0.65em;
        text-align: center;
    }

    .headerInfoPodrobne img{
        display: inline;
        width: 2vh;
        min-width: 8px;
        vertical-align: middle;
    } 

    .headerInfoPodrobne p{
        display: inline;
        padding-left:5px;
        vertical-align: middle;
    }

    @media (min-aspect-ratio: 5/4) {
        .loaderHeader {
            grid-column: span 4;
            margin-top: 0;
            justify-self: center;
        }

        .headerAktualne{
            height: 100%;
        }

        .headerLogo{
            grid-column: span 6;
            justify-self: end;
            align-self: center;
            text-align: center;
            margin-right: calc(2vw + 20px);
        }

        .headerAktualne .data{
            grid-column: span 6;
            justify-self: start;
            align-self: center;
            margin-left: calc(2vw + 20px);
        }

        .headerLogoTitle{
            font-size: 4.5em;
        }

        .headerIkona{
            min-height: 100px;
            max-height: 25vh;
        }

        .headerInfo{
            font-size: 1.2em;
        }

    }
</style>