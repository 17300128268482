<template>
    <div v-if="online.stanica==1" class="archiv grid">
        <div class="title grid">
            <p>Absolútne rekordy</p>
        </div>
        <div v-if="data!=null" class="sekcia grid">
            <div class="infoItem large grid">
                <div class="label">
                    <img src="../assets/ikony/black/teplota.png" alt="" class="ikona">
                    Max. teplota
                </div>
                <div class="data">{{data.teplotaMax.hodnota}}°C</div>
                <div class="minimum"></div>
                <div class="maximum">{{data.teplotaMax.datum}}</div>
            </div>
            <div class="infoItem large grid">
                <div class="label">
                    <img src="../assets/ikony/black/teplota.png" alt="" class="ikona">
                    Min. teplota
                </div>
                <div class="data">{{data.teplotaMin.hodnota}}°C</div>
                <div class="minimum"></div>
                <div class="maximum">{{data.teplotaMin.datum}}</div>
            </div>
            <div v-if="online.zrazky==1" class="infoItem large grid">
                <div class="label">
                    <img src="../assets/ikony/black/gauge.png" alt="" class="ikona">
                    Zrážky (deň)
                </div>
                <div class="data">{{data.zrazkyDen.hodnota}}mm</div>
                <div class="minimum"></div>
                <div class="maximum">{{data.zrazkyDen.datum}}</div>
            </div>
            <div v-if="online.zrazky==1" class="infoItem large grid">
                <div class="label">
                    <img src="../assets/ikony/black/gauge.png" alt="" class="ikona">
                    Zrážky (mesiac)
                </div>
                <div class="data">{{data.zrazkyMesiac.hodnota}}mm</div>
                <div class="minimum"></div>
                <div class="maximum">{{data.zrazkyMesiac.datum}}</div>
            </div>
            <div v-if="online.vietor==1" class="infoItem grid">
                <div class="label">
                    <img src="../assets/ikony/black/vietor.png" alt="" class="ikona">
                    Vietor
                </div>
                <div class="data">{{data.vietor.hodnota}}m/s</div>
                <div class="minimum"></div>
                <div class="maximum">{{data.vietor.datum}}</div>
            </div>
            <div v-if="online.oblacnost==1" class="infoItem large grid">
                <div class="label">
                    <img src="../assets/ikony/black/svetlo.png" alt="" class="ikona">
                    Slnečno (mesiac)
                </div>
                <div class="data">{{data.slnecno.hodnota}}</div>
                <div class="minimum"></div>
                <div class="maximum">{{data.slnecno.datum}}</div>
            </div>
            <div class="infoItem grid">
                <div class="label">
                    <img src="../assets/ikony/black/tlak.png" alt="" class="ikona">
                    Max. tlak
                </div>
                <div class="data">{{data.tlakMax.hodnota}}hPa</div>
                <div class="minimum"></div>
                <div class="maximum">{{data.tlakMax.datum}}</div>
            </div>
            <div class="infoItem grid">
                <div class="label">
                    <img src="../assets/ikony/black/tlak.png" alt="" class="ikona">
                    Min. tlak
                </div>
                <div class="data">{{data.tlakMin.hodnota}}hPa</div>
                <div class="minimum"></div>
                <div class="maximum">{{data.tlakMin.datum}}</div>
            </div>
        </div>
        <div v-else class="loader">
            <div class="loaderItem">Načítavam údaje</div>
            <Loader class="loaderItem" />
        </div>
        <div class="title grid">
            <p>Archív meraní</p>
            <Datepicker inputClassName="inputClass" class="datePicker" v-model="range" :disabled="rangeDisabled" range :minDate="minDate" :maxDate="new Date()" maxRange="365" :partialRange="false" locale="sk" format="d. M. yyyy" monthNameFormat="long" autoApply placeholder="Zvoľte rozmedzie dátumov..." :enableTimePicker="false" @update:modelValue="datePickerUpdate"/>
        </div>
        <div v-if="loaded && range!=null" class="sekcia grid">
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/teplota.png" alt="" class="ikona">
                    Teplota
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[0]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/vlhkost.png" alt="" class="ikona">
                    Vlhkosť
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[1]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/tlak.png" alt="" class="ikona">
                    Tlak
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[2]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div v-if="online.oblacnost==1" class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/oblacnost.png" alt="" class="ikona">
                    Oblačnosť
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[3]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div v-if="online.oblacnost==1" class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/LCL.png" alt="" class="ikona">
                    výška oblakov
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[5]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div v-if="online.vietor==1" class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/teplota.png" alt="" class="ikona">
                    Rýchlosť vetra
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[7]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div v-if="online.vietor==1" class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/smer.png" alt="" class="ikona">
                    Smer vetra
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[12]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div v-if="online.zrazky==1" class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/gauge.png" alt="" class="ikona">
                    Úhrny zrážok ({{uhrny_str}})
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[4]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div v-if="online.zrazky==1" class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/zrazky.png" alt="" class="ikona">
                    Zrážky
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[6]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div v-if="online.svetlo==1" class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/svetlo.png" alt="" class="ikona">
                    Osvetlenie
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[8]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div v-if="online.slnko==1" class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/svetlo.png" alt="" class="ikona">
                    sln. energia
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[9]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div v-if="online.ovzdusie==1" class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/PM10.png" alt="" class="ikona">
                    Prachové častice
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[11]" class="highChartsGraf"></highcharts>
                </div>
            </div>
            <div v-if="online.ovzdusie==1" class="infoItem grid">
                <div class="label archiv">
                    <img src="../assets/ikony/black/PM10.png" alt="" class="ikona">
                    Prachové častice (24h)
                </div>
                <div class="graf">
                    <highcharts :options="chartOptions[10]" class="highChartsGraf"></highcharts>
                </div>
            </div>
        </div>
        <div v-else-if="range!=null" class="loader">
            <div class="loaderItem">Načítavam údaje</div>
            <Loader class="loaderItem" />
        </div>
        <div v-else class="loader">
            <p>Nie je zvolený dátum</p>
        </div>
    </div>
    <div v-else class="loader">
        <div class="loaderItem">Stanica {{lokalita}} OFFLINE</div>
        <img src="../assets/ikony/black/offline.png"/>
    </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Loader from "./Loader.vue";
import Highcharts from "highcharts";

export default {
    emits: ["updateInterface","loaded","updateHeight"],
    components: { Datepicker,Loader },
    data(){
        return {
            minDate: new Date(),
            range: null,
            loaded: false,
            rangeDisabled: true,
            uhrny_str: "",
            online: {
                stanica: 1,
                oblacnost: 1,
                zrazky: 1,
                vietor: 1,
                slnko: 0,
                svetlo: 1,
                ovzdusie: 1
            },
            data: null,
            updated: "-",
            lokalita:"",
            lokalitaID: 1,
            chartOptions: [
                {//0 teplota
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:[
                        {
                            labels:{
                                align: "left",
                                format: "{text}°C"
                            }
                        }
                    ],
                    series: [
                        {
                            name: "Teplota",
                            type: "spline",
                            tooltip: {
                                valueSuffix: " °C",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ],
                    legend:{
                        enabled: false
                    }
                },{//1 vlhkost
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:[
                        {
                            labels:{
                                align: "left",
                                format: "{text}%"
                            }
                        }
                    ],
                    series: [
                        {
                            name: "Vlhkosť vzduchu",
                            tooltip: {
                                valueSuffix: " %",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ],
                    legend:{
                        enabled: false
                    }
                },{//2 tlak
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:[
                        {
                            labels:{
                                align: "left",
                                format: "{text}hPa"
                            }
                        }
                    ],
                    series: [
                        {
                            name: "Tlak",
                            tooltip: {
                                valueSuffix: " hPa",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ],
                    legend:{
                        enabled: false
                    }
                },{//3 oblacnost
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:[
                        {
                            labels:{
                                align: "right",
                                format: "{text}%",
                                x: -3,
                                y: 3
                            },
                            min: 0,
                            max: 100
                        }
                    ],
                    series: [
                        {
                            name: "Oblačnosť",
                            tooltip: {
                                valueSuffix: " %",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ],
                    legend:{
                        enabled: false
                    },
                    chart:{
                        marginLeft: undefined,
                        marginRight: undefined,
                    }
                },{//4 uhrny zrazok
                    xAxis:{
                        type: "datetime",
                        
                    },
                    yAxis:{
                        labels:{
                            align: "right",
                            format: "{text}mm",
                            x: -8,
                            y: 3
                        }
                    },
                    series: [
                        {
                            name: "Úhrny zrážok",
                            type: "column",
                            tooltip: {
                                valueSuffix: " mm",
                                valueDecimals: 1
                            },
                            data: [],
                        }
                    ],
                    legend:{
                        enabled: false
                    },
                    chart:{
                        marginLeft: undefined,
                        marginRight: undefined
                    }
                },{//5 LCL
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:{
                        labels:{
                            align: "left",
                            format: "{text}m"
                        }
                    },
                    series: [
                        {
                            name: "Výška oblakov",
                            tooltip: {
                                valueSuffix: " m",
                                valueDecimals: 0
                            },
                            data: null
                        }
                    ],
                    legend:{
                        enabled: false
                    }
                },{//6 zrazky
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:{
                        labels:{
                            align: "left",
                            y: -5
                        },
                        min: 1,
                        max: 8,
                        type: "category",
                        tickInterval: 1,
                        tickmarkPlacement: "on",
                        categories: ["","Bez zrážok", "Mrholenie", "Slabý dážď", "Dážď", "Silný dážď", "Lejak", "Prívalový dážď", "Sneženie"]
                    },
                    series: [
                        {
                            name: "Zrážky",
                            type: "scatter",
                            marker: {
                                radius: 2
                            },
                            tooltip: {
                                valueDecimals: 0
                            },
                            data: null
                        }
                    ],
                    chart:{
                        marginLeft: undefined,
                        marginRight: undefined
                    },
                    tooltip:{
                        /*headerFormat: '<span style="font-size: 10px"> {point.x:%A, %e. %b, %H:%M:%S}</span><br/>',
                        pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.key}</b><br/>',
                        */
                       formatter: (point)=>{
                            const datum = Highcharts.dateFormat('%A, %e. %b, %H:%M', point.chart.hoverPoint.x);
                            const farba = point.chart.hoverPoint.color;
                            const meno = point.chart.hoverPoint.series.name;
                            const kategoria = point.chart.axes[1].categories[point.chart.hoverPoint.y];
                            return '<span style="font-size: 10px">'+datum+'</span><br/><span style="color:'+farba+'">●</span> '+meno+': <b>'+kategoria+'</b><br/>';
                        }
                    },
                    legend:{
                        enabled: false
                    }
                },{//7 rychlost vetra
                    xAxis:{
                        type: "datetime",
                        gridLineWidth: 0
                    },
                    legend:{
                        enabled: false
                    },
                    chart:{
                        marginLeft: undefined,
                        marginRight: undefined
                    },
                    yAxis:{
                        labels:{
                            align: "right",
                            format: "{text}m/s",
                            x: -5,
                            y: 3
                        },
                        min: 0,
                        softMax: 10,
                        plotBands: [
                            {
                                from: 0.2,
                                to: 1.5,
                                color: 'hsl(201, 100%, 100%)',
                                label: {
                                    text: 'Vánok',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 1.5,
                                to: 3.3,
                                color: 'hsl(201, 100%, 97%)',
                                label: {
                                    text: 'Slabý vietor',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 3.3,
                                to: 5.4,
                                color: 'hsl(201, 100%, 94%)',
                                label: {
                                    text: 'Mierny vietor',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 5.4,
                                to: 10.7,
                                color: 'hsl(201, 100%, 91%)',
                                label: {
                                    text: 'Čerstvý vietor',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 10.7,
                                to: 13.8,
                                color: 'hsl(201, 100%, 88%)',
                                label: {
                                    text: 'Silný vietor',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 13.8,
                                to: 17.1,
                                color: 'hsl(201, 100%, 85%)',
                                label: {
                                    text: 'Prudký vietor',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 17.1,
                                to: 20.7,
                                color: 'hsl(201, 100%, 82%)',
                                label: {
                                    text: 'Búrlivý vietor',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 20.7,
                                to: 32.6,
                                color: 'hsl(201, 100%, 79%)',
                                label: {
                                    text: 'Víchrica',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 32.6,
                                color: 'hsl(201, 100%, 76%)',
                                label: {
                                    text: 'Orkán',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }
                        ]
                    },
                    series: [
                        {
                            name: "Rýchlosť vetra",
                            tooltip: {
                                valueSuffix: " m/s",
                                valueDecimals: 0
                            },
                            data: null
                        }
                    ]
                },{//8 LUX
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:{
                        labels:{
                            align: "left",
                            format: "{text}Lx"
                        },
                        min:0,
                        softMax: 100
                    },
                    series: [
                        {
                            name: "Intenzita svetla",
                            tooltip: {
                                valueSuffix: " Lx",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ],
                    legend:{
                        enabled: false
                    }
                },{//9 energia
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:{
                        labels:{
                            align: "left",
                            format: "{text}W"
                        },
                        min:0,
                        softMax: 100
                    },
                    series: [
                        {
                            name: "Slnečná energia",
                            tooltip: {
                                valueSuffix: " W/m2",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ],
                    legend:{
                        enabled: false
                    }
                },{//10 PM 24h
                    xAxis:{
                        type: "datetime",
                        labels:{
                            format:"{value:%e. %b}"
                        }
                    },
                    yAxis:{
                        labels:{
                            align: "right",
                            format: "{text}μg",
                            x: -8,
                            y: 3
                        },
                        min: 0,
                        softMax: 1
                    },
                    series: [
                        {
                            type: "column",
                            color: "rgb(102, 102, 102)",
                            name: "PM 2.5 (24h)",
                            tooltip: {
                                valueSuffix: " μg/m3",
                                valueDecimals: 1
                            },
                            data: [],
                        },{
                            type: "column",
                            name: "PM 10 (24h)",
                            tooltip: {
                                valueSuffix: " μg/m3",
                                valueDecimals: 1
                            },
                            data: [],
                        }
                    ],
                    chart:{
                        marginLeft: undefined,
                        marginRight: undefined
                    },
                    tooltip:{
                        xDateFormat: "%A, %e. %b %Y"
                    }
                },{//11 PM25,10
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:{
                        labels:{
                            align: "left",
                            format: "{text}μg"
                        },
                        min:0,
                        softMax: 50
                    },
                    series: [
                        {
                            name: "PM2.5",
                            tooltip: {
                                valueSuffix: " μg/m3",
                                valueDecimals: 1
                            },
                            data: null
                        },{
                            name: "PM10",
                            tooltip: {
                                valueSuffix: " μg/m3",
                                valueDecimals: 1
                            },
                            color: "rgb(210, 210, 210)",
                            data: null
                        }
                    ]
                },{//12 smer vetra
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:{
                        labels:{
                            align: "left",
                            y: -5
                        },
                        min: 1,
                        max: 8,
                        type: "category",
                        tickInterval: 1,
                        tickmarkPlacement: "on",
                        categories: ["","S", "SV", "V", "JV", "J", "JZ", "Z", "SZ"]
                    },
                    series: [
                        {
                            name: "Smer vetra",
                            type: "scatter",
                            marker: {
                                radius: 2
                            },
                            tooltip: {
                                valueDecimals: 0
                            },
                            data: null
                        }
                    ],
                    chart:{
                        marginLeft: undefined,
                        marginRight: undefined
                    },
                    tooltip:{
                        /*headerFormat: '<span style="font-size: 10px"> {point.x:%A, %e. %b, %H:%M:%S}</span><br/>',
                        pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.key}</b><br/>',
                        */
                       formatter: (point)=>{
                            const datum = Highcharts.dateFormat('%A, %e. %b, %H:%M', point.chart.hoverPoint.x);
                            const farba = point.chart.hoverPoint.color;
                            const meno = point.chart.hoverPoint.series.name;
                            const kategoria = point.chart.axes[1].categories[point.chart.hoverPoint.y];
                            return '<span style="font-size: 10px">'+datum+'</span><br/><span style="color:'+farba+'">●</span> '+meno+': <b>'+kategoria+'</b><br/>';
                        }
                    },
                    legend:{
                        enabled: false
                    }
                }
            ]
        }
    },
    mounted(){
        this.emitInterface();
    },
    methods:{
        datePickerUpdate(){
            setTimeout(() => {
                this.$emit("updateHeight");
            }, 100);
            if(this.range==null)return;
            if(this.range[0].toDateString()===this.range[1].toDateString())this.range=null;
            else this.getArchivData();
        },

        async getMinDate(){
            var data = await fetch("PHP/loadMinDate.php?lokalita="+this.lokalitaID);
            var dataJson = await data.json();
            this.minDate=new Date(dataJson.minDate);
            this.rangeDisabled=false;
            this.online.stanica=dataJson.online;
        },

        async getArchivData(){
            if(this.range===null){
                this.$emit("loaded","archiv")
                return;
            }

            var start=new Date(this.range[0]);
            var end=new Date(this.range[1]);

            var diff=start.getTimezoneOffset()

            start=start.getTime()/1000;
            start-=diff*60;
            start=Math.floor(start/86400)*86400;
            start+=diff*60;

            end=end.getTime()/1000;
            end-=diff*60;
            end=Math.floor(end/86400)*86400;
            end+=diff*60;

            //console.log(start);
            //console.log(end);

            this.online.stanica=1;
            this.loaded=false;

            var data=null;
            var dataJson=null;

            data = await fetch("PHP/loadArchiv.php?start="+start+"&end="+end+"&lokalita="+this.lokalitaID);
            dataJson = await data.json(); 
            this.online.stanica=dataJson.state.online;
            
            if(!this.online.stanica){
                this.$emit("loaded","archiv")
                return;
            }

            this.online.oblacnost=dataJson.state.oblacnost;
            this.online.vietor=dataJson.state.vietor;
            this.online.zrazky=dataJson.state.zrazky;
            this.online.svetlo=dataJson.state.svetlo;
            this.online.slnko=dataJson.state.slnko;
            this.online.ovzdusie=dataJson.state.ovzdusie;

            //console.log(this.online);

            this.chartOptions[0].series[0].data=dataJson.teplota;
            this.chartOptions[1].series[0].data=dataJson.vlhkost;
            this.chartOptions[2].series[0].data=dataJson.tlak;
            this.chartOptions[3].series[0].data=dataJson.oblacnost;
            this.chartOptions[4].series[0].data=dataJson.uhrny;
            this.chartOptions[4].series[0].pointRange=dataJson.uhrny_range;
            this.chartOptions[5].series[0].data=dataJson.LCL;
            this.chartOptions[6].series[0].data=dataJson.zrazky;
            this.chartOptions[7].series[0].data=dataJson.vietor;
            this.chartOptions[8].series[0].data=dataJson.svetlo;
            this.chartOptions[9].series[0].data=dataJson.energia;
            this.chartOptions[10].series[0].data=dataJson.PMS24_25;
            this.chartOptions[10].series[1].data=dataJson.PMS24_10;
            this.chartOptions[11].series[0].data=dataJson.PM25;
            this.chartOptions[11].series[1].data=dataJson.PM10;
            this.chartOptions[12].series[0].data=dataJson.smer_vetra;

            this.uhrny_str=dataJson.uhrny_range_str;

            this.loaded=true;
            setTimeout(() => {
                this.$emit("updateHeight");
            }, 100);
        },

        async updateMe(lokalita){

            this.data=null;
            if(this.lokalita!=lokalita)this.range=null;

            var data=null;
            var dataJson=null;

            this.lokalita=lokalita?"Sásová":"Centrum";
            this.lokalitaID=lokalita;

            data = await fetch("PHP/loadRekordy.php?lokalita="+lokalita);
            dataJson = await data.json(); 

            this.online.stanica=dataJson.state.online;
            this.online.oblacnost=dataJson.state.oblacnost;
            this.online.vietor=dataJson.state.vietor;
            this.online.zrazky=dataJson.state.zrazky;
            this.online.svetlo=dataJson.state.svetlo;
            this.online.slnko=dataJson.state.slnko;
            this.online.ovzdusie=dataJson.state.ovzdusie;
            
            if(!this.online.stanica){
                this.$emit("loaded","archiv")
                return;
            }

            this.data=dataJson.data;

            this.getMinDate();

            
            this.$emit("loaded","archiv")
            setTimeout(() => {
                this.$emit("updateHeight");
            }, 100);
            return;
        },
        emitInterface(){
            this.$emit("updateInterface",{id: "archiv",updateInterface: this.updateMe})
        }
    }
}
</script>

<style>
.archiv .loader{
    grid-column: span 12;
}

.inputClass{
    font-family: 'Roboto Slab', serif !important;
    height: 2em;
}

.archiv{
    padding: 0 15px;
}

.archiv .title{
    text-transform: uppercase;
    grid-column: span 12;
    margin: 0 calc(1vw + 5px);
    margin-top: 22px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 1.5em;
    font-weight: 700;
    border-bottom: 3px solid var(--color1);
    align-items: center;
}

.archiv .title p{
    grid-column: span 12;
    margin: 0;
    display: inline-block;
}

.archiv .datePicker{
    grid-column: span 12;
    margin-top: 10px; 
    font-family: 'Roboto Slab', serif !important;
}

.archiv .sekcia{
    grid-column: span 12;
    /* margin-top: 40px; */
    margin-bottom: 40px;
}

.archiv .infoItem{
    grid-column: span 12;
    font-weight: 300;
    margin: 0 calc(1vw + 10px);
    padding: 22px 0;
    border-bottom: 1px solid rgb(230, 230, 230);
    align-self: start;
    height: calc(100% - 44px);
}

.archiv .infoItem:last-child{
    border-bottom: none;
}

.archiv .infoItem .ikona{
    height: 1.2em;
    vertical-align: middle;
    padding-right: 3px;
}

.archiv .infoItem .label.archiv{
    grid-column: span 12;
}

.archiv .infoItem .label{
    text-transform: uppercase;
    grid-column: span 7;
    align-self: center;
    font-size: 1.1em;
    font-weight: 500;
}

.archiv .infoItem.small .label{
    grid-column: span 5;
}

.archiv .infoItem.small .data{
    grid-column: span 7;
    font-size: 1.3em;
    line-height: 1.8em;
}

.archiv .infoItem.large .label{
    grid-column: span 8; 
}

.archiv .infoItem.large .data{
    grid-column: span 4;
}

.archiv .graf{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: span 12;
    overflow: hidden;
    height: calc(150px + 15vh);
    padding-top: 20px;
}

.archiv .highChartsGraf{
    margin-bottom: 0 !important;
}

.archiv .infoItem .data{
    grid-column: span 5;
    font-weight: 700;
    justify-self: right;
    align-self: center;
    font-size: 1.8em;
    color: var(--textColor3);
}
.archiv .infoItem .maximum{
    grid-column: 7/13;
    justify-self: right;
    font-size: 0.75em;
    color: var(--textColor3);
    font-weight: 100;
    margin-top: 2px;
}

.archiv .infoItem .minimum{
    grid-column: 1/7;
    font-size: 0.75em;
    color: var(--textColor3);
    font-weight: 100;
    margin-top: 2px;
}

@media screen and (min-width: 620px) {
    .archiv .infoItem{
        grid-column: span 6;
        padding: 25px 0;
        height: calc(100% - 50px);
    }

    .archiv .grafHlavny{
        width: calc(100% - (2vw + 20px));
        margin-left: calc(1vw + 10px);
        margin-right: calc(1vw + 10px);
    }
 
    .archiv .title p{
        grid-column: span 6;
    }

    .archiv .datePicker{
        grid-column: span 6;
        margin-top: 0; 
    }

    .archiv .infoItem .minimum,
    .archiv .infoItem .maximum{
        font-weight: 300;
    }

    .archiv .infoItem:nth-last-child(2):nth-child(odd){
        border-bottom: 0;
    }
}
</style>