<template>
    <div class="info grid">
        <div class="title">
            <p>O meteostanici</p>
        </div>
        <div class="sekcia grid">
            <p>
            Projekt Meteostanice Sásová začal vznikať začiatkom roka 2016 ako hobby projekt. Testovacia prevádzka prvého prototypu s niekoľkými senzormi a jedoduchým webovým rozhraním začala približne v máji 2016 a počas leta sa projekt dostal až do finálnej fazy. Plnohodnotné meranie dát sa začalo 28. októbra 2016 a prvá verejná verzia webovej stránky bola spustená následne v novembri.
            </p>

            <p>
                Na jar v roku 2022 sa projekt MeteoSásová rozšíril o druhú meteostanicu v centre mesta. Bola tiež spustená úplne nová webová aplikácia a celý projekt bol premenovaný na MeteoBB.
            </p>
            
            <p>
            Prvá meteostanica je od svojho vzniku umiestnená na panelovom dome v Sásovej (Banská Bystrica). Dve vonkajšie zariadenia sú umiestnené vo výške cca 15m (teplota, vlhkosť, tlak, zrážky, ovzdušie a z nich odvodené údaje) a 36m (merače vetra, slnečné žiarenie a oblačnosť). Druhá meteostanica je umiestnená v centre Banskej Bystrice, v časti Sídlisko.
            </p>
            
            <p>
            Meranie údajov prebieha každých približne 20s. Tieto dáta sú v plnom rozsahu archivované 48h. Celkovo sú merania archivované bez obmedzenia času s periodicitou 1 hodiny.
            </p>

            <p class="bbo">
                Už od začiatku projektu je hlavným partnerom MeteoBB banskobystrický spravodajský portál BBonline.sk, kde tiež možete nájsť niektoré údaje z naších meteostaníc.
            </p>
            <a class="bbo" href="http://bbonline.sk" target="_blank">
                <img src="../assets/logoBBo.png" alt="">
            </a>
        </div>
        <div class="title">
            <p>Kontakty</p>
        </div>
        <div class="sekcia">
            <form class="vue-form" @submit.prevent="odoslat">
                <div v-show="messageErr!=''" class="error-message">
                    <p>{{messageErr}}</p>
                </div>
                <div v-show="messageOk!=''" class="ok-message">
                    <p>{{messageOk}}</p>
                </div>

                <div>
                    <label class="label" for="name">Meno</label>
                    <input type="text" name="name" id="name" required="" v-model="meno">
                </div>
                <div>
                    <label class="label" for="email">E-mail</label>
                    <input type="email" name="email" id="email" required=""
                        :class="{error: !emailValid }"
                        v-model="email">
                </div>
                <div>
                    <label class="label" for="textarea">Správa</label>
                    <textarea class="message" name="textarea" id="textarea" required="" 
                            v-model="message"></textarea>
                </div>
                <div>
                    <input type="submit" value="Odoslať">
                </div>

            </form>
        </div>
    </div>
</template>

<script>
import { watch } from '@vue/runtime-core';
export default {
    emits: ["updateInterface","loaded"],
    data(){
        return {
            meno: "",
            email: "",
            emailValid: true,
            sprava: "",
            odoslane: false,
            message: "",
            messageErr: "",
            messageOk: "",
            lokalita: null
        }
    },
    mounted(){
        this.emitInterface()
    },
    methods:{
        updateMe(lokalita){
            this.lokalita=lokalita;
            this.$emit("loaded","info");
        },
        emitInterface(){
            this.$emit("updateInterface",{id: "info",updateInterface: this.updateMe})
        },

        checkMail(){
            const emailRegex = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
            this.emailValid = emailRegex.test(this.email);
        },

        async odoslat(){
            if(!this.emailValid){
                this.messageErr="E-mail má nesprávny formát...";
                setTimeout(() => {
                    this.messageErr="";
                }, 3000);
                return;
            }
            var data = await fetch("PHP/infoSendMail.php?meno="+this.meno+"&mail="+this.email+"&sprava="+this.message+"&lokalita="+this.lokalita);
            var dataJson = await data.json();
            if(dataJson.status==="ERR"){
                this.messageErr=dataJson.return;
            }else{
                this.messageOk=dataJson.return;
                this.meno="";
                this.email="";
                this.emailValid=true;
                this.message="";
            }
            setTimeout(() => {
                this.messageErr="";
                this.messageOk="";
            }, 3000);
        }
    },

    watch: {
        email(){
            if(this.messageOk==="")this.checkMail();
        }
    }
}
</script>

<style>
.info{
    padding: 0 15px;
}

.info .title{
    text-transform: uppercase;
    grid-column: span 12;
    margin: 0 calc(1vw + 5px);
    margin-top: 22px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 1.5em;
    font-weight: 700;
    border-bottom: 3px solid var(--color1);
    align-items: center;
}

.info .title p{
    margin: 0;
    display: inline-block;
    padding-bottom: 5px;
}

.info .sekcia{
    grid-column: span 12;
    margin: 0 calc(1vw + 10px);
    margin-bottom: 40px;
    padding: 0 ;
}

.info .sekcia p{
    grid-column: span 12;
}

.info .sekcia a.bbo{
    grid-column: span 12;
    width: 100%;
    margin: 10px 0;
}

.info .sekcia a.bbo img{
    width: 100%;
}







.vue-form div {
  position: relative;
  margin: 20px 0;
}

.vue-form .label {
  margin-bottom: 10px;
  display: block;
  font-weight: 500;
}

.vue-form input,
.vue-form textarea,
.vue-form label {
  color: var(--textColor3);
}

.vue-form input[type="text"],
.vue-form input[type="email"],
.vue-form textarea{
  display: block;
  width: calc(100% - 24px);
  appearance: none;
  padding: 12px;
  border: 1px solid #cfd9db;
  background-color: #ffffff;
  border-radius: 0.25em;
  font-family: inherit;
  font-weight: 300;
}

.vue-form input[type="text"]:focus,
.vue-form input[type="email"]:focus,
.vue-form textarea:focus{
  outline: none;
  border-color: var(--color1);
}

.vue-form textarea {
  min-height: 120px;
  resize: none;
  overflow: auto;
}

.vue-form input[type="submit"] {
  border: none;
  background: var(--color1);
  border-radius: 0.25em;
  padding: 12px 20px;
  color: #ffffff;
  font-weight: bold;
  float: right;
  cursor: pointer;
  appearance: none;
  font-family: inherit;
  font-size: 1em;
}


.vue-form .error-message {
  height: 35px;
  margin: 0px;
}

.vue-form .error-message p {
  background: #dd4632;
  color: #ffffff;
  font-size: 1.4rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 0.25em;
  padding: 16px;
}

.vue-form .ok-message p {
  background: #20881dbe;
  color: #ffffff;
  font-size: 1.4rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-radius: 0.25em;
  padding: 16px;
}

.vue-form .error {
  border-color: #e94b35 !important;
}




@media screen and (min-width: 620px) {
    .info .sekcia p.bbo{
        grid-column: span 8 !important; 
        margin: 10px 0;
    }

    .info .sekcia a.bbo{
        grid-column: span 4 !important; 
        justify-self: right;
        width: 90%;
        margin: 10px;
    }
}
</style>