<template>
    <div v-if="online.stanica" class="podrobne grid">

        <!-- OBLACNOST A ZRAZKY -->

        <div v-if="data.oblacnost==null || online.oblacnost || online.zrazky" class="title" id="titleOblacnost">
            <p>oblačnosť a zrážky</p>
        </div>
        <div v-if="data.oblacnost!=null && (online.oblacnost || online.zrazky)" class="sekcia grid">
            <div v-if="online.oblacnost" class="infoItem grid">
                <div class="label">
                    <img src="../assets/ikony/black/oblacnost.png" alt="" class="ikona">
                    Oblačnosť
                </div>
                <div class="data">{{data.oblacnost.oblacnost}}</div>
                <div class="minimum">Dnes jasno: {{data.oblacnost.dnesJasno}}</div>
                <div class="maximum">Dnes zamračené: {{data.oblacnost.dnesZamracene}}</div>
            </div>
            <div v-if="online.oblacnost" class="infoItem large grid" :class="{extend:extended==2}">
                <div class="label clickable" @click="toggleGraf(2)">
                    <img src="../assets/ikony/black/LCL.png" alt="" class="ikona">
                    výška oblakov
                    <img src="../assets/ikony/white/chart.png" alt="" class="ikonaChart">
                </div>
                <div class="data">{{data.oblacnost.LCL}}</div>
                <div class="minimum">Minimum: {{data.oblacnost.LCLmin}} ({{data.oblacnost.LCLminTime}})</div>
                <div class="maximum">Maximum: {{data.oblacnost.LCLmax}} ({{data.oblacnost.LCLmaxTime}})</div>
                <div class="skrytyGraf" :class="{extend:extend==2}">
                    <highcharts v-if="chartOptions[2].series[0].data!=null" :options="chartOptions[2]" class="highChartsGraf"></highcharts>
                    <div v-else class="grafLoader">
                        <Loader class="loaderItem" />
                    </div>
                </div>
            </div>

            <highcharts v-if="chartOptions[0].series[0].data!=null || chartOptions[0].series[1].data!=null" :options="chartOptions[0]" class="grafHlavny borderBottom"></highcharts>

            <div v-if="online.zrazky" class="infoItem small grid" :class="{extend:extended==3}">
                <div class="label clickable" @click="toggleGraf(3)">
                    <img src="../assets/ikony/black/zrazky.png" alt="" class="ikona">
                    zrážky
                    <img src="../assets/ikony/white/chart.png" alt="" class="ikonaChart">
                </div>
                <div class="data">{{data.oblacnost.zrazky}}</div>
                <div class="skrytyGraf" :class="{extend:extend==3}">
                    <highcharts v-if="chartOptions[3].series[0].data!=null" :options="chartOptions[3]" class="highChartsGraf"></highcharts>
                    <div v-else class="grafLoader">
                        <Loader class="loaderItem" />
                    </div>
                </div>
            </div>
            <div v-if="online.zrazky" class="infoItem grid">
                <div class="label">
                    <img src="../assets/ikony/black/gauge.png" alt="" class="ikona">
                    Úhrny za 24h
                </div>
                <div class="data">{{data.oblacnost.uhrny24}}</div>
                <div class="minimum">Posledných 12h: {{data.oblacnost.uhrny12}}</div>
                <div class="maximum">Posledné 3h: {{data.oblacnost.uhrny3}}</div>
            </div>
            <div v-if="online.zrazky" class="infoItem full large grid noBorder">
                <div class="label">
                    <img src="../assets/ikony/black/gauge.png" alt="" class="ikona">
                    Mesačné úhrny
                </div>
                <div class="data">
                    <select name="rok" class="rokSelect" id="rokSelect" @change="changeYear" :class="{rokNoSelect: currentYear==null}">
                        <option value="0" :selected="currentYear==null">Rok...</option>
                        <option v-for="year in years" :key="year" :selected="year==currentYear" :value="year">{{year}}</option>
                    </select>
                </div>
                <div class="skrytyGraf" :class="{extend:currentYear!=null}">
                    <highcharts v-if="chartOptions[1].series[0].data!=null" :options="chartOptions[1]" class="highChartsGraf"></highcharts>
                    <div v-else class="grafLoader">
                        <Loader class="loaderItem" />
                    </div>
                </div>
                
            </div>
        </div>
        <div v-else-if="data.oblacnost==null" class="loader">
            <div class="loaderItem">Načítavam údaje</div>
            <Loader class="loaderItem" />
        </div>
        <!-- VIETOR -->

        <div class="title" v-if="data.vietor==null || online.vietor" id="titleVietor">
            <p>Vietor</p>
        </div>
        <div v-if="data.vietor!=null && online.vietor" class="sekcia grid">
            <div class="infoItem small grid">
                <div class="label">
                    <img src="../assets/ikony/black/teplota.png" alt="" class="ikona">
                    stav
                </div>
                <div class="data">{{data.vietor.vietorStav}}</div>
                <div class="minimum">Smer vetra: {{data.vietor.vietorSmer}}</div>
                <div class="maximum">Najvet. deň: {{data.vietor.vietorMax}}</div>
            </div>
            <div class="infoItem grid">
                <div class="label">
                    <img src="../assets/ikony/black/teplota.png" alt="" class="ikona">
                    Rýchlosť
                </div>
                <div class="data">{{data.vietor.vietorRychlost}}</div>
                <div class="minimum">Maximum: {{data.vietor.vietorRychlost}}</div>
                <div class="maximum">Priemer: {{data.vietor.vietorPriemer}}</div>
            </div>
            <highcharts v-if="chartOptions[4].series[0].data!=null" :options="chartOptions[4]" class="grafHlavny"></highcharts>
        </div>
        <div v-else-if="data.vietor==null" class="loader">
            <div class="loaderItem">Načítavam údaje</div>
            <Loader class="loaderItem" />
        </div>

        <!-- SLNKO A MESIAC -->

        <div class="title" id="titleSlnko">
            <p>Slnko a mesiac</p>
        </div>
        <div v-if="data.slnko!=null" class="sekcia grid">
            <div class="infoItem large grid" :class="{extend:extended==20, noBorderLarge: !online.slnko && !online.svetlo}">
                <div class="label clickable" @click="toggleGraf(20,1)">
                    <img :src="sunVZImg" alt="" class="ikona">
                    {{data.slnko.sunDataStr}}
                    <img src="../assets/ikony/white/chart.png" alt="" class="ikonaChart">
                </div>
                <div class="data">{{data.slnko.sunDataTime}}</div>
                <div class="minimum">{{data.slnko.sunDataMale}}</div>
                <div class="maximum">Dĺžka dňa: {{data.slnko.dlzkaDna}}</div>
                <div class="skrytyGraf" :class="{extend:extend==20}">
                    <div class="highChartsGraf">
                        <canvas id="slnkoPoziciaCanvas"></canvas>
                    </div>
                </div>
            </div>
            <div class="infoItem grid" :class="{noBorder: !online.slnko && !online.svetlo}">
                <div class="label">
                    <img src="../assets/ikony/black/moon.png" alt="" class="ikona">
                    Fáza mesiaca
                </div>
                <div class="data">
                    <img :src="moonFazaImg" alt="" class="ikonaMesiac">
                    {{data.slnko.moonFaza}}
                </div>
                <div class="minimum">Východ mesiaca: {{data.slnko.vychodMesiac}}</div>
                <div class="maximum">Západ mesiaca: {{data.slnko.zapadMesiac}}</div>
            </div>
            <div v-if="online.svetlo" class="infoItem grid" :class="{extend:extended==6, noBorder:!online.slnko}">
                <div class="label clickable" @click="toggleGraf(6)">
                    <img src="../assets/ikony/black/svetlo.png" alt="" class="ikona">
                    Osvetlenie
                    <img src="../assets/ikony/white/chart.png" alt="" class="ikonaChart">
                </div>
                <div class="data">{{data.slnko.lux}}</div>
                <div class="minimum">UV Index: {{data.slnko.uvi}}</div>
                <div class="maximum">Maximum: {{data.slnko.maxLux}}</div>
                <div class="skrytyGraf" :class="{extend:extend==6}">
                    <highcharts v-if="chartOptions[6].series[0].data!=null" :options="chartOptions[6]" class="highChartsGraf"></highcharts>
                    <div v-else class="grafLoader">
                        <Loader class="loaderItem" />
                    </div>
                </div>
            </div>
            <div v-if="online.slnko" class="infoItem large grid" :class="{extend:extended==7}">
                <div class="label clickable" @click="toggleGraf(7)">
                    <img src="../assets/ikony/black/svetlo.png" alt="" class="ikona">
                    sln. energia
                    <img src="../assets/ikony/white/chart.png" alt="" class="ikonaChart">
                </div>
                <div class="data">{{data.slnko.radiacia}}</div>
                <div class="minimum">Dnes celkovo: {{data.slnko.sumRadiacia}}</div>
                <div class="maximum">Maximum: {{data.slnko.maxRadiacia}}</div>
                <div class="skrytyGraf" :class="{extend:extend==7}">
                    <highcharts v-if="chartOptions[7].series[0].data!=null" :options="chartOptions[7]" class="highChartsGraf"></highcharts>
                    <div v-else class="grafLoader">
                        <Loader class="loaderItem" />
                    </div>
                </div>
            </div>
            <highcharts v-if="chartOptions[5].series[0].data!=null && online.slnko" :options="chartOptions[5]" class="grafHlavny"></highcharts>
            <div v-else-if="online.slnko" >
                <Loader class="loaderItem" />
            </div>
        </div>
        <div v-else class="loader">
            <div class="loaderItem">Načítavam údaje</div>
            <Loader class="loaderItem" />
        </div>

        <!-- OVZDUSIE -->

        <div class="title" v-if="data.ovzdusie==null || online.ovzdusie" id="titleOvzdusie">
            <p>Ovzdušie</p>
        </div>
        <div v-if="data.ovzdusie!=null && online.ovzdusie" class="sekcia grid">
            <div class="infoItem grid" :class="{extend:extended==9}">
                <div class="label clickable" @click="toggleGraf(9)">
                    <img src="../assets/ikony/black/PM25.png" alt="" class="ikona">
                    PM2.5
                    <img src="../assets/ikony/white/chart.png" alt="" class="ikonaChart">
                </div>
                <div class="data">{{data.ovzdusie.PM25}}</div>
                <div class="skrytyGraf" :class="{extend:extend==9}">
                    <highcharts v-if="chartOptions[9].series[0].data!=null" :options="chartOptions[9]" class="highChartsGraf"></highcharts>
                    <div v-else class="grafLoader">
                        <Loader class="loaderItem" />
                    </div>
                </div>
            </div>
            <div class="infoItem grid" :class="{extend:extended==10}">
                <div class="label clickable" @click="toggleGraf(10)">
                    <img src="../assets/ikony/black/PM10.png" alt="" class="ikona">
                    PM10
                    <img src="../assets/ikony/white/chart.png" alt="" class="ikonaChart">
                </div>
                <div class="data">{{data.ovzdusie.PM10}}</div>
                <div class="skrytyGraf" :class="{extend:extend==10}">
                    <highcharts v-if="chartOptions[10].series[0].data!=null" :options="chartOptions[10]" class="highChartsGraf"></highcharts>
                    <div v-else class="grafLoader">
                        <Loader class="loaderItem" />
                    </div>
                </div>
            </div>
            <highcharts v-if="chartOptions[8].series[0].data!=null" :options="chartOptions[8]" class="grafHlavny"></highcharts>
        
        </div>
        <div v-else class="loader">
            <div class="loaderItem">Načítavam údaje</div>
            <Loader class="loaderItem" />
        </div>
        <div class="update">
            <img src="../assets/ikony/white/update.png" alt="" class="ikona">
            Aktualizované: {{updated}}
        </div>
    </div>
    <div v-else class="loader">
        <div class="loaderItem">Stanica {{lokalita}} OFFLINE</div>
        <img src="../assets/ikony/black/offline.png"/>
    </div>
</template>

<script>

import Loader from "./Loader.vue"
import Highcharts from "highcharts"

export default {
    components: {Loader},
    emits: ["updateInterface","loaded","updateHeight","scrollArray"],
    data(){
        return {
            data: {
                oblacnost: null,
                vietor: null,
                slnko: 1,
                ovzdusie: 1
            },
            online: {
                stanica: 1,
                oblacnost: 1,
                zrazky: 1,
                vietor: 1,
                slnko: 1,
                svetlo: 1,
                ovzdusie: 1
            },
            updated: "-",
            years:[],
            currentYear:null,
            lokalita:"",
            lokalitaID: 1,
            extend: -1,
            extended: -1,
            sunVZImg: "",
            moonFazaImg: "",
            chartOptions: [
                {///0 OBLACNOST,UHRNY
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:[
                        {
                            labels:{
                                align: "right",
                                format: "{text}%",
                                style:{
                                    color: "rgb(0, 129, 197)"
                                },
                                x: -3,
                                y: 3
                            },
                            min: 0,
                            max: 100
                        },
                        {
                            labels:{
                                align: "left",
                                format: "{text}mm",
                                style:{
                                    color: "rgb(102, 102, 102)"
                                },
                                x: 3,
                                y: 3
                            },
                            opposite: true,
                            allowDecimals: true
                        }
                    ],
                    series: [
                        {
                            name: "Oblačnosť",
                            tooltip: {
                                valueSuffix: " %",
                                valueDecimals: 1
                            },
                            data: null,
                            yAxis: 0,
                            zIndex: 1
                        },
                        {
                            name: "Úhrny zrážok",
                            type: "column",
                            color: "rgb(210, 210, 210)",
                            states: {
                                hover: {
                                    brightness: -0.3
                                }
                            },
                            tooltip: {
                                valueSuffix: " mm",
                                valueDecimals: 1
                            },
                            data: [],
                            yAxis: 1,
                            pointRange: 3600000,
                            zIndex: 0
                        }
                    ],
                    legend:{
                        verticalAlign: "top"
                    },
                    chart:{
                        marginLeft: undefined,
                        marginRight: undefined,
                    }
                },{//1 mesacne uhrny
                    xAxis:{
                        type: "datetime",
                        labels:{
                            format:"{value:%b}"
                        }
                    },
                    yAxis:{
                        labels:{
                            align: "right",
                            format: "{text}mm",
                            x: -8,
                            y: 3
                        }
                    },
                    series: [
                        {
                            type: "column",
                            name: "Mesačné úhrny",
                            tooltip: {
                                valueSuffix: " mm",
                                valueDecimals: 1
                            },
                            data: [],
                        }
                    ],
                    legend:{
                        enabled: false
                    },
                    chart:{
                        marginLeft: undefined,
                        marginRight: undefined
                    }
                },{//2 LCL
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:{
                        labels:{
                            align: "left",
                            format: "{text}m"
                        }
                    },
                    series: [
                        {
                            name: "Výška oblakov",
                            tooltip: {
                                valueSuffix: " m",
                                valueDecimals: 0
                            },
                            data: null
                        }
                    ]
                },{//3 zrazky
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:{
                        labels:{
                            align: "left",
                            y: -5
                        },
                        min: 1,
                        max: 8,
                        type: "category",
                        tickInterval: 1,
                        tickmarkPlacement: "on",
                        categories: ["","Bez zrážok", "Mrholenie", "Slabý dážď", "Dážď", "Silný dážď", "Lejak", "Prívalový dážď", "Sneženie"]
                    },
                    series: [
                        {
                            name: "Zrážky",
                            type: "scatter",
                            marker: {
                                radius: 2
                            },
                            tooltip: {
                                valueDecimals: 0
                            },
                            data: null
                        }
                    ],
                    chart:{
                        marginLeft: undefined,
                        marginRight: undefined
                    },
                    tooltip:{
                        /*headerFormat: '<span style="font-size: 10px"> {point.x:%A, %e. %b, %H:%M:%S}</span><br/>',
                        pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.key}</b><br/>',
                        */
                       formatter: (point)=>{
                            const datum = Highcharts.dateFormat('%A, %e. %b, %H:%M', point.chart.hoverPoint.x);
                            const farba = point.chart.hoverPoint.color;
                            const meno = point.chart.hoverPoint.series.name;
                            const kategoria = point.chart.axes[1].categories[point.chart.hoverPoint.y];
                            return '<span style="font-size: 10px">'+datum+'</span><br/><span style="color:'+farba+'">●</span> '+meno+': <b>'+kategoria+'</b><br/>';
                        }
                    }
                },{//4 RYCHLOST VETRA
                    xAxis:{
                        type: "datetime",
                        gridLineWidth: 0
                    },
                    legend:{
                        verticalAlign: "top"
                    },
                    chart:{
                        marginLeft: undefined,
                        marginRight: undefined
                    },
                    yAxis:{
                        labels:{
                            align: "right",
                            format: "{text}m/s",
                            x: -5,
                            y: 3
                        },
                        min: 0,
                        softMax: 10,
                        plotBands: [
                            {
                                from: 0.2,
                                to: 1.5,
                                color: 'hsl(201, 100%, 100%)',
                                label: {
                                    text: 'Vánok',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 1.5,
                                to: 3.3,
                                color: 'hsl(201, 100%, 97%)',
                                label: {
                                    text: 'Slabý vietor',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 3.3,
                                to: 5.4,
                                color: 'hsl(201, 100%, 94%)',
                                label: {
                                    text: 'Mierny vietor',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 5.4,
                                to: 10.7,
                                color: 'hsl(201, 100%, 91%)',
                                label: {
                                    text: 'Čerstvý vietor',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 10.7,
                                to: 13.8,
                                color: 'hsl(201, 100%, 88%)',
                                label: {
                                    text: 'Silný vietor',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 13.8,
                                to: 17.1,
                                color: 'hsl(201, 100%, 85%)',
                                label: {
                                    text: 'Prudký vietor',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 17.1,
                                to: 20.7,
                                color: 'hsl(201, 100%, 82%)',
                                label: {
                                    text: 'Búrlivý vietor',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 20.7,
                                to: 32.6,
                                color: 'hsl(201, 100%, 79%)',
                                label: {
                                    text: 'Víchrica',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }, {
                                from: 32.6,
                                color: 'hsl(201, 100%, 76%)',
                                label: {
                                    text: 'Orkán',
                                    align: "right",
                                    textAlign: "right",
                                    x: -5
                                }
                            }
                        ]
                    },
                    series: [
                        {
                            name: "Rýchlosť vetra",
                            tooltip: {
                                valueSuffix: " m/s",
                                valueDecimals: 0
                            },
                            data: null
                        }
                    ]
                },{//5 SUN RAD DAYS
                    xAxis:{
                        type: "datetime",
                        labels:{
                            format:"{value:%e. %b}"
                        }
                    },
                    yAxis:{
                        labels:{
                            align: "right",
                            format: "{text}kWh",
                            x: -8,
                            y: 3
                        },
                        min: 0,
                        softMax: 1
                    },
                    series: [
                        {
                            type: "column",
                            name: "Slnečná energia - denná",
                            tooltip: {
                                valueSuffix: " kWh",
                                valueDecimals: 1
                            },
                            data: [],
                        }
                    ],
                    legend:{
                        verticalAlign: "top"
                    },
                    chart:{
                        marginLeft: undefined,
                        marginRight: undefined
                    }
                },{//6 LUX
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:{
                        labels:{
                            align: "left",
                            format: "{text}Lx"
                        },
                        min:0,
                        softMax: 100
                    },
                    series: [
                        {
                            name: "Intenzita svetla",
                            tooltip: {
                                valueSuffix: " Lx",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ]
                },{//7 ENERGIA
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:{
                        labels:{
                            align: "left",
                            format: "{text}W"
                        },
                        min:0,
                        softMax: 100
                    },
                    series: [
                        {
                            name: "Slnečná energia",
                            tooltip: {
                                valueSuffix: " W/m2",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ]
                },{//8 PM 24h
                    xAxis:{
                        type: "datetime",
                        labels:{
                            format:"{value:%e. %b}"
                        }
                    },
                    yAxis:{
                        labels:{
                            align: "right",
                            format: "{text}μg",
                            x: -8,
                            y: 3
                        },
                        min: 0,
                        softMax: 1
                    },
                    series: [
                        {
                            type: "column",
                            color: "rgb(102, 102, 102)",
                            name: "PM 2.5 (24h)",
                            tooltip: {
                                valueSuffix: " μg/m3",
                                valueDecimals: 1
                            },
                            data: [],
                        },{
                            type: "column",
                            name: "PM 10 (24h)",
                            tooltip: {
                                valueSuffix: " μg/m3",
                                valueDecimals: 1
                            },
                            data: [],
                        }
                    ],
                    legend:{
                        verticalAlign: "top"
                    },
                    chart:{
                        marginLeft: undefined,
                        marginRight: undefined
                    },
                    tooltip:{
                        xDateFormat: "%A, %e. %b %Y"
                    }
                },{//9 PM25
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:{
                        labels:{
                            align: "left",
                            format: "{text}μg"
                        },
                        min:0,
                        softMax: 50
                    },
                    series: [
                        {
                            name: "Častice PM2.5",
                            tooltip: {
                                valueSuffix: " μg/m3",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ]
                },{//10 PM10
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:{
                        labels:{
                            align: "left",
                            format: "{text}μg"
                        },
                        min:0,
                        softMax: 50
                    },
                    series: [
                        {
                            name: "Častice PM10",
                            tooltip: {
                                valueSuffix: " μg/m3",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ]
                }
            ]
        }
    },
    mounted(){
        this.emitInterface()
        //console.log("PODROBNE_MOUNTED");
    },
    methods:{
        
        async loadGraf(graf){
            const data = await fetch("PHP/loadPodrobneGrafy.php?lokalita="+this.lokalitaID+"&graf="+graf);
            const dataJson = await data.json(); 
            if(dataJson.online){
                this.chartOptions[graf].series[0].data=dataJson.data
            }
        },

        showGraf(newGraf,notLoad=0){
            if(!notLoad){
                if(this.chartOptions[newGraf].series[0].data==null){
                    this.loadGraf(newGraf)

                }
            }
            this.extend=newGraf;
            this.extended=newGraf;
            this.currentYear=null;
            setTimeout(() => {
                this.$emit("updateHeight");
            }, 600);
        },

        hideGraf(){
            this.extend=-1;
            setTimeout(() => {
                this.extended=-1
                setTimeout(() => {
                    this.$emit("updateHeight");
                }, 100);
            }, 500);
        },

        toggleGraf(newGraf,notLoad=0){
            if(this.extend!=newGraf){
                this.showGraf(newGraf,notLoad)
            }else{
                this.hideGraf()
            }
        },

        changeYear(event){
            //console.log(event.target.value);
            this.updateYearGauge(event.target.value);
        },

        async updateYearGauge(newYear){

            if(newYear==0 || newYear==null){
                this.currentYear=null;
                return;
            }

            const data = await fetch("PHP/loadYearGauge.php?lokalita="+this.lokalitaID+(newYear!=null?("&rok="+newYear):""));
            const dataJson = await data.json(); 
            this.currentYear=dataJson.year;
            this.chartOptions[1].series[0].data=dataJson.grafGaugeYear;
        },

        async updateMe(lokalita){
            this.currentYear=null;

            this.online.stanica=1;
            this.lokalita=lokalita?"Sásová":"Centrum"
            this.lokalitaID=lokalita

            var data=null;
            var dataJson=null;

            var scrollArray=[];

            //OBLACNOST, ZRAZKY
            data = await fetch("PHP/loadPodrOblZr.php?lokalita="+lokalita);
            dataJson = await data.json(); 
            this.online.stanica=dataJson.online;
            if(!this.online.stanica){
                this.data={oblacnost: null,vietor: null,slnko: null,ovzdusie: null};
                this.$emit("loaded","podrobne")
                return;
            }
            this.online.oblacnost=dataJson.oblacnostOnline;
            this.online.zrazky=dataJson.zrazkyOnline;

            if(this.online.oblacnost){
                this.chartOptions[0].series[0].data=dataJson.grafOblacnost;
            }

            if(this.online.zrazky){
                this.chartOptions[0].series[1].data=dataJson.grafGauge;
                this.years=dataJson.yearList;
                //this.updateYearGauge(this.currentYear);
                
            }

            if(this.online.zrazky || this.online.oblacnost)scrollArray.push({"id":0,"dom":document.getElementById("titleOblacnost")})
            this.chartOptions[2].series[0].data=null
            this.chartOptions[3].series[0].data=null
            this.data.oblacnost=dataJson.data;
            this.updated=dataJson.aktualizacia;

            //VIETOR
            data = await fetch("PHP/loadPodrVietor.php?lokalita="+lokalita);
            dataJson = await data.json(); 
            this.online.stanica=dataJson.online;
            if(!this.online.stanica){
                this.data={oblacnost: null,vietor: null,slnko: null,ovzdusie: null};
                this.$emit("loaded","podrobne")
                return;
            }
            this.online.vietor=dataJson.vietorOnline;

            if(this.online.vietor){
                this.chartOptions[4].series[0].data=dataJson.grafVietorRychlost;
                scrollArray.push({"id":1,"dom":document.getElementById("titleVietor")})
            }

            this.data.vietor=dataJson.data;
            this.updated=dataJson.aktualizacia;

            //SLNKO
            data = await fetch("PHP/loadPodrSlnko.php?lokalita="+lokalita);
            dataJson = await data.json(); 
            this.online.stanica=dataJson.online;
            if(!this.online.stanica){
                this.data={oblacnost: null,vietor: null,slnko: null,ovzdusie: null};
                this.$emit("loaded","podrobne")
                return;
            }
            this.online.slnko=dataJson.slnkoOnline;
            this.online.svetlo=dataJson.svetloOnline;

            if(this.online.slnko){
                this.chartOptions[5].series[0].data=dataJson.grafSlnRadDays;
            }

            scrollArray.push({"id":2,"dom":document.getElementById("titleSlnko")})

            this.chartOptions[6].series[0].data=null
            this.chartOptions[7].series[0].data=null

            this.data.slnko=dataJson.data;
            this.vykresliSlnkoCanvas(dataJson.sunAltitude,dataJson.sunAzimuth,dataJson.moonAltitude,dataJson.moonAzimuth,dataJson.sunDraha,dataJson.moonDraha);
            this.updated=dataJson.aktualizacia;
            this.sunVZImg=require("../assets/ikony/black/"+this.data.slnko.sunDataImg);
            this.moonFazaImg=require("../assets/ikony/black/"+this.data.slnko.moonImg);

            //OVZDUSIE
            data = await fetch("PHP/loadPodrOvzd.php?lokalita="+lokalita);
            dataJson = await data.json(); 
            this.online.stanica=dataJson.online;
            if(!this.online.stanica){
                this.data={oblacnost: null,vietor: null,slnko: null,ovzdusie: null};
                this.$emit("loaded","podrobne")
                return;
            }
            this.online.ovzdusie=dataJson.ovzdusieOnline;

            if(this.online.ovzdusie){
                this.chartOptions[8].series[1].data=dataJson.grafPMS24_10;
                this.chartOptions[8].series[0].data=dataJson.grafPMS24_25;
                scrollArray.push({"id":3,"dom":document.getElementById("titleOvzdusie")})
            }

            this.chartOptions[9].series[0].data=null
            this.chartOptions[10].series[0].data=null

            this.data.ovzdusie=dataJson.data;
            this.updated=dataJson.aktualizacia;
            
            this.hideGraf();

            this.$emit("scrollArray",scrollArray);
            this.$emit("loaded","podrobne");
        },

        vykresliSlnkoCanvas(sunAltitude,sunAzimut,moonAltitude,moonAzimut,sunDraha,moonDraha){
            
            var ratio = Math.ceil(window.devicePixelRatio);

            var canv = $("#slnkoPoziciaCanvas");
            //console.log(canv.get(0));
            if(canv.get(0)==undefined){
                setTimeout(() => {
                    this.vykresliSlnkoCanvas(sunAltitude,sunAzimut,moonAltitude,moonAzimut,sunDraha,moonDraha)
                }, 10);
                return;
            }
            var par = canv.parent();
            var xP = par.width();
            var yP = par.height()-20;

            canv.attr("width",yP*ratio);
            canv.attr("height",yP*ratio);
            canv.css("margin-left",(xP-yP)/2);
            canv.css("width",yP);
            canv.css("height",yP);

            yP*=ratio;

            var ctx = canv.get(0).getContext("2d");
            
            var x=yP/2;
            var y=yP/2;
            var r=(yP*118)/300;
                                   
            
            var rS=r*Math.cos(sunAltitude);
            var x1s=x+(rS*Math.sin(sunAzimut));
            var y1s=y-(rS*Math.cos(sunAzimut));
            
            var rM=r*Math.cos(moonAltitude);
            var x1m=x+(rM*Math.sin(moonAzimut));
            var y1m=y-(rM*Math.cos(moonAzimut));
            
            var moonImg = new Image();
            var sunImg = new Image();
            var BG = new Image();
    
            moonImg.onload = function() {
                ctx.drawImage(moonImg, x1m-(10*ratio), y1m-(10*ratio),20*ratio,20*ratio);
            };
    
            sunImg.onload = function() {
                ctx.drawImage(sunImg, x1s-(10*ratio), y1s-(10*ratio),20*ratio,20*ratio);
            };
            
            var lineWidth = 2*ratio;
    
            BG.onload = function() {
                
                ctx.clearRect(0, 0, yP,yP);
                ctx.drawImage(BG, 0, 0,yP,yP);

                if (typeof sunDraha !== 'undefined') {
                    //slnko
                    
                    var riseS=[0,0];
                    var setS=[0,0];
                    
                    ctx.beginPath();
                    ctx.lineWidth=lineWidth.toString();
                    ctx.strokeStyle="rgba(0,129,197,0.5)";
                    
                    for(var i=0;i<=50;i++){
                        var d_r=r*Math.cos(sunDraha[i][0]);
                
                        var d_x1=x+(d_r*Math.sin(sunDraha[i][1]));
                        var d_y1=y-(d_r*Math.cos(sunDraha[i][1]));
                        
                        if(i==0)ctx.moveTo(d_x1,d_y1);
                        else ctx.lineTo(d_x1,d_y1);
                        
                        if(i==0){
                            riseS[0]=d_x1;
                            riseS[1]=d_y1;
                        }
                        if(i==50){
                            setS[0]=d_x1;
                            setS[1]=d_y1;
                        }
                        
                    }
                    ctx.stroke();
                    
                    ctx.beginPath();
                    ctx.lineWidth=lineWidth.toString();
                    ctx.strokeStyle="rgba(0,129,197,0.5)";
                    ctx.moveTo(x,y);
                    ctx.lineTo(riseS[0],riseS[1]);
                    ctx.stroke();
                    
                    ctx.beginPath();
                    ctx.lineWidth=lineWidth.toString();
                    ctx.strokeStyle="rgba(0,129,197,0.5)";
                    ctx.moveTo(x,y);
                    ctx.lineTo(setS[0],setS[1]);
                    ctx.stroke();
                    
                }
                if(typeof moonDraha !== 'undefined'){  
                    //mesiac
                    
                    var riseM=[0,0];
                    var setM=[0,0];
                    
                    ctx.beginPath();
                    ctx.lineWidth=lineWidth.toString();
                    ctx.strokeStyle="rgba(102,201,255,0.5)";
                    
                    for(var i=0;i<=50;i++){
                        var d_r=r*Math.cos(moonDraha[i][0]);
                
                        var d_x1=x+(d_r*Math.sin(moonDraha[i][1]));
                        var d_y1=y-(d_r*Math.cos(moonDraha[i][1]));
                        
                        if(i==0)ctx.moveTo(d_x1,d_y1);
                        else ctx.lineTo(d_x1,d_y1);
                        
                        if(i==0){
                            riseM[0]=d_x1;
                            riseM[1]=d_y1;
                        }
                        if(i==50){
                            setM[0]=d_x1;
                            setM[1]=d_y1;
                        }
                        
                    }
                    ctx.stroke();
                    
                    ctx.beginPath();
                    ctx.lineWidth=lineWidth.toString();
                    ctx.strokeStyle="rgba(102,201,255,0.5)";
                    ctx.moveTo(x,y);
                    ctx.lineTo(riseM[0],riseM[1]);
                    ctx.stroke();
                    
                    ctx.beginPath();
                    ctx.lineWidth=lineWidth.toString();
                    ctx.strokeStyle="rgba(102,201,255,0.5)";
                    ctx.moveTo(x,y);
                    ctx.lineTo(setM[0],setM[1]);
                    ctx.stroke();
                    
                }
                
                
                
                if(sunAltitude>0)sunImg.src = require("../assets/ikony/black/sunPosVis.png");
                else sunImg.src = require("../assets/ikony/black/sunPosInvis.png");
                
                if(moonAltitude>0)moonImg.src = require("../assets/ikony/black/moonPosVis.png");
                else moonImg.src = require("../assets/ikony/black/moonPosInvis.png");
            };
            
            BG.src = require("../assets/ikony/white/sunPosBGgrey.png");
        },

        emitInterface(){
            this.$emit("updateInterface",{id: "podrobne",updateInterface: this.updateMe})
        }
    }
}
</script>

<style>
.podrobne .grafLoader{
    grid-column: span 12;
    justify-self: center;
    height: calc(150px + 15vh);
    display: flex;
    align-items: center;
}

.rokSelect{
    appearance: none;
    border: 0;
    outline: 0;
    font: inherit;
    background: url("../assets/ikony/black/arrow.png") no-repeat right 0 center / 0.5em;
    cursor: pointer;
    padding-right: 0.8em;
    font-size: 0.8em;
    text-align: right;
    color: var(--textColor3);
}

.rokNoSelect{
    color: var(--textColor2);
}

.rokSelect option{
    font-size: 0.8em;
    font-weight: 500;
    text-align: center;
    color: var(--textColor3);
}

.rokSelect:focus {
	outline: none;
}

.rokSelect::-ms-expand {
	display: none;
}

.podrobne .loader{
    grid-column: span 12;
}

.podrobne .title p{
    margin: 0;
    display: inline-block;
    padding-bottom: 5px;
}


.podrobne .infoItem .label.clickable{
    cursor: pointer;
}

.podrobne .infoItem .ikonaMesiac{
    height: 0.6em;
    vertical-align: middle;
}

.grafHlavny{
    grid-column: span 12;
    height: calc(150px + 15vh);
    width: 100%;
    margin-top: 5px;
    margin-bottom: 15px;
    padding: 30px 0;
}

.grafHlavny.inside{
    width: 100%;
    margin-top: 15px;
}

.grafHlavny.mensi{
    height: calc(100px + 12vh);
}

.podrobne{
    padding: 0 15px;
}

.podrobne .sekcia{
    grid-column: span 12;
    /* margin-top: 40px; */
    margin-bottom: 40px;
}

.podrobne .title{
    text-transform: uppercase;
    grid-column: span 12;
    margin: 0 calc(1vw + 5px);
    margin-top: 22px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    font-size: 1.5em;
    font-weight: 700;
    border-bottom: 3px solid var(--color1);
}

.topBorder{
    border-top: 1px solid rgb(230, 230, 230) !important;
}

.noBorder{
    border-bottom: 0 !important;
}

.borderBottom{
    border-bottom: 1px solid rgb(230, 230, 230);
    margin-bottom: 0;
}

.podrobne .infoItem{
    grid-column: span 12;
    font-weight: 300;
    margin: 0 calc(1vw + 10px);
    padding: 22px 0;
    border-bottom: 1px solid rgb(230, 230, 230);
    align-self: start;
    height: calc(100% - 44px);
}

/* .podrobne .infoItem:last-child{
    padding-bottom:0;
    margin-bottom: -15px;
} */

.podrobne .infoItem.extend{
    grid-row: span 5;
    height: auto;
}

.podrobne .infoItem.extend .label{
    font-weight: 900;
}

.podrobne .infoItem .ikona{
    height: 1.2em;
    vertical-align: middle;
    padding-right: 3px;
}

.podrobne .infoItem .label{
    text-transform: uppercase;
    grid-column: span 7;
    align-self: center;
    font-size: 1.1em;
    font-weight: 500;
}

.podrobne .infoItem .data{
    grid-column: span 5;
    font-weight: 700;
    justify-self: right;
    align-self: center;
    font-size: 1.8em;
    color: var(--textColor3);
}

.podrobne .infoItem .ikonaChart{
    height: 0.8em;
    vertical-align: middle;
    padding-left: 3px;
}
 
/* .podrobne .infoItem:nth-last-child(1){
    border-bottom: 0;
} */

.podrobne .infoItem .maximum{
    grid-column: 7/13;
    justify-self: right;
    font-size: 0.75em;
    color: var(--textColor3);
    font-weight: 100;
    margin-top: 2px;
}

.podrobne .infoItem .minimum{
    grid-column: 1/7;
    font-size: 0.75em;
    color: var(--textColor3);
    font-weight: 100;
    margin-top: 2px;
}

.podrobne .infoItem.small .label{
    grid-column: span 5;
}

.podrobne .infoItem.small .data{
    grid-column: span 7;
    font-size: 1.3em;
    line-height: 1.8em;
}

.podrobne .infoItem.large .label{
    grid-column: span 8;
}

.podrobne .infoItem.large .data{
    grid-column: span 4;
}


.podrobne .update{
    grid-column: span 12;
    justify-self: center;
    color: var(--textColor3);
    font-size: 0.85em;
    font-weight: 100;
    margin-top: -50px;
    margin-bottom: 50px;
}

.podrobne .update .ikona{
    height: 1em;
    vertical-align:middle;
}

@media screen and (min-width: 620px) {
    .podrobne .infoItem{
        grid-column: span 6;
        padding: 25px 0;
        height: calc(100% - 50px);
    }

    .podrobne .infoItem.full{
        grid-column: span 12;
    }

    .podrobne .infoItem .minimum,
    .podrobne .infoItem .maximum{
        font-weight: 300;
    }

    .podrobne .grafHlavny{
        width: calc(100% - (2vw + 20px));
        margin-left: calc(1vw + 10px);
        margin-right: calc(1vw + 10px);
    }

    .noBorderLarge{
        border-bottom: 0 !important;
    }
 
    /* .podrobne .infoItem:nth-last-child(2):nth-child(odd){
        border-bottom: 0;
    }  */
}
</style>