<template>
    <div v-if="data!=null" class="aktualne grid">
        <div class="infoItem suhrn grid">

            <div class="label">
                <img src="../assets/ikony/black/location.png" alt="" class="ikona">
                {{lokalita}}
            </div>

            <div class="infoSuhrn">
                {{data.aktualne}}
            </div>

            <div class="minimum">{{data.slnkoStr}}: {{data.slnkoTime}}</div>
            <div class="maximum">Fáza mesiaca: {{data.mesiac}}</div>

            <div class="slnkoMesiacData">
                <div class="popis">{{data.slnkoStr}}</div>
                <img :src="slnkoImg" alt="">
                {{data.slnkoTime}}
            </div>

            <div class="slnkoMesiacData right">
                <div class="popis">Fáza mesiaca</div>
                <img :src="moonPhaseImg" alt="">
                {{data.mesiac}}
            </div>

        </div>
        
        <div class="infoItem grid" :class="{extend:extended==0}">
            <div class="label clickable" @click="toggleGraf(0)">
                <img src="../assets/ikony/black/teplota.png" alt="" class="ikona">
                Teplota
                <img src="../assets/ikony/white/chart.png" alt="" class="ikonaChart">
            </div>
            <div class="data">{{data.teplota}}</div>
            <div class="minimum">Minimum: {{data.min.teplota[0]}} ({{data.min.teplota[1]}})</div>
            <div class="maximum">Maximum: {{data.max.teplota[0]}} ({{data.max.teplota[1]}})</div>
            <div class="skrytyGraf" :class="{extend:extend==0}">
                <highcharts v-if="chartOptions[0].series[0].data!=null" :options="chartOptions[0]" class="highChartsGraf"></highcharts>
                <div v-else class="grafLoader">
                    <Loader class="loaderItem" />
                </div>
            </div>
        </div>
        <div class="infoItem grid" :class="{extend:extended==1}">
            <div class="label clickable" @click="toggleGraf(1)">
                <img src="../assets/ikony/black/pocitovo.png" alt="" class="ikona">
                Pocitovo
                <img src="../assets/ikony/white/chart.png" alt="" class="ikonaChart">
            </div>
            <div class="data">{{data.pocitovo}}</div>
            <div class="minimum">Minimum: {{data.min.RF[0]}} ({{data.min.RF[1]}})</div>
            <div class="maximum">Maximum: {{data.max.RF[0]}} ({{data.max.RF[1]}})</div>
            <div class="skrytyGraf" :class="{extend:extend==1}">
                <highcharts v-if="chartOptions[1].series[0].data!=null" :options="chartOptions[1]" class="highChartsGraf"></highcharts>
                <div v-else class="grafLoader">
                    <Loader class="loaderItem" />
                </div>
            </div>
        </div>
        <div class="infoItem grid" :class="{extend:extended==2}">
            <div class="label clickable" @click="toggleGraf(2)">
                <img src="../assets/ikony/black/vlhkost.png" alt="" class="ikona">
                Vlhkosť
                <img src="../assets/ikony/white/chart.png" alt="" class="ikonaChart">
            </div>
            <div class="data">{{data.vlhkost}}</div>
            <div class="minimum">Minimum: {{data.min.vlhkost[0]}} ({{data.min.vlhkost[1]}})</div>
            <div class="maximum">Maximum: {{data.max.vlhkost[0]}} ({{data.max.vlhkost[1]}})</div>
            <div class="skrytyGraf" :class="{extend:extend==2}">
                <highcharts v-if="chartOptions[2].series[0].data!=null" :options="chartOptions[2]" class="highChartsGraf"></highcharts>
                <div v-else class="grafLoader">
                    <Loader class="loaderItem" />
                </div>
            </div>
        </div>
        <div class="infoItem grid small" :class="{extend:extended==3}">
            <div class="label clickable" @click="toggleGraf(3)">
                <img src="../assets/ikony/black/tlak.png" alt="" class="ikona">
                Tlak
                <img src="../assets/ikony/white/chart.png" alt="" class="ikonaChart">
            </div>
            <div class="data">
                <img :src="tlakTendImg" alt="" class="ikonaTlak">
                {{data.tlak}}
            </div>
            <div class="minimum">Minimum: {{data.min.tlak[0]}} ({{data.min.tlak[1]}})</div>
            <div class="maximum">Maximum: {{data.max.tlak[0]}} ({{data.max.tlak[1]}})</div>
            <div class="skrytyGraf" :class="{extend:extend==3}">
                <highcharts v-if="chartOptions[3].series[0].data!=null" :options="chartOptions[3]" class="highChartsGraf"></highcharts>
                <div v-else class="grafLoader">
                    <Loader class="loaderItem" />
                </div>
            </div>
        </div>
        <div v-if="data.vietorEnabled" class="infoItem grid">
            <div class="label">
                <img src="../assets/ikony/black/vietor.png" alt="" class="ikona">
                Vietor
            </div>
            <div class="data">{{data.vietor}}</div>
            <div class="maximum">Maximum: {{data.max.vietor[0]}} ({{data.max.vietor[1]}})</div>
        </div>
        <div v-if="data.zrazkyEnabled" class="infoItem grid">
            <div class="label">
                <img src="../assets/ikony/black/zrazky.png" alt="" class="ikona">
                Zrážky za 24h
            </div>
            <div class="data">{{data.zrazky}}</div>
            <div class="minimum">Posledné 3h: {{data.zrazky3}}</div>
            <div class="maximum">Posledná 1h: {{data.zrazky1}}</div>
        </div>
        
        <div class="update">
            <img src="../assets/ikony/white/update.png" alt="" class="ikona">
            Aktualizované: {{data.update}}
        </div>
    </div>
    <div v-else-if="online" class="loader">
        <div class="loaderItem">Načítavam údaje</div>
        <Loader class="loaderItem" />
    </div>
    <div v-else class="loader">
        <div class="loaderItem">Stanica {{lokalita}} OFFLINE</div>
        <img src="../assets/ikony/black/offline.png"/>
    </div>
</template>

<script>

import Loader from "./Loader.vue"


export default {
    components: {Loader},
    emits: ["updateInterface","loaded","updateHeight"],
    data(){
        return {
            extend: -1,
            extended: -1,
            online: 1,
            data: null,
            tlakTendImg: "",
            moonPhaseImg: "",
            slnkoImg: "",
            lokalita:"",
            lokalitaID:1,
            chartOptions: [
                {//0 TEPLOTA
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:[
                        {
                            labels:{
                                align: "left",
                                format: "{text}°C"
                            }
                        }
                    ],
                    series: [
                        {
                            name: "Teplota",
                            tooltip: {
                                valueSuffix: " °C",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ]
                },{//1 POCITOVA
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:[
                        {
                            labels:{
                                align: "left",
                                format: "{text}°C"
                            }
                        }
                    ],
                    series: [
                        {
                            name: "Pocitová teplota",
                            tooltip: {
                                valueSuffix: " °C",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ]
                },{//VLHKOST
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:[
                        {
                            labels:{
                                align: "left",
                                format: "{text}%"
                            }
                        }
                    ],
                    series: [
                        {
                            name: "Vlhkosť vzduchu",
                            tooltip: {
                                valueSuffix: " %",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ]
                },{//TLAK
                    xAxis:{
                        type: "datetime"
                    },
                    yAxis:[
                        {
                            labels:{
                                align: "left",
                                format: "{text}hPa"
                            }
                        }
                    ],
                    series: [
                        {
                            name: "Tlak",
                            tooltip: {
                                valueSuffix: " hPa",
                                valueDecimals: 1
                            },
                            data: null
                        }
                    ]
                }
            ]
        }
    },
    mounted(){
        this.emitInterface();
        
    },
    methods:{
        async loadGraf(graf){
            const data = await fetch("PHP/loadAktualGrafy.php?lokalita="+this.lokalitaID+"&graf="+graf);
            const dataJson = await data.json(); 
            if(dataJson.online){
                //console.log(graf)
                //console.log(this.chartOptions)
                this.chartOptions[graf].series[0].data=dataJson.data
            }
        },

        showGraf(newGraf){
            if(this.chartOptions[newGraf].series[0].data==null){
                this.loadGraf(newGraf)

            }
            this.extend=newGraf;
            this.extended=newGraf;
            setTimeout(() => {
                this.$emit("updateHeight");
            }, 600);
        },

        hideGraf(){
            this.extend=-1;
            setTimeout(() => {
                this.extended=-1
                setTimeout(() => {
                    this.$emit("updateHeight");
                }, 100);
            }, 500);
        },

        toggleGraf(newGraf){
            if(this.extend!=newGraf){
                this.showGraf(newGraf)
            }else{
                this.hideGraf()
            }
        },

        minMax(data){
            var min=data[0].y,max=data[0].y
            data.forEach(item => {
                if(item.y>max)max=item.y
                if(item.y<min)min=item.y
            });
            min=Math.floor(min);
            max=Math.ceil(max);
            return [min,max];
        },

        async updateMe(lokalita){
            //console.log("ACTUAL_LOAD");
            this.online=1;
            const data = await fetch("PHP/loadAktual.php?lokalita="+lokalita);
            const dataJson = await data.json(); 
            this.lokalita=lokalita?"Sásová":"Centrum"
            this.lokalitaID=lokalita
            this.online=dataJson.online;
            if(!this.online){
                this.data=null;
                this.$emit("loaded","aktual")
                return;
            }
            this.chartOptions[0].series[0].data=dataJson.teplotaGraf
            this.chartOptions[1].series[0].data=null
            this.chartOptions[2].series[0].data=null
            this.chartOptions[3].series[0].data=null

            this.hideGraf();

            this.data=dataJson.data
           
            
            this.tlakTendImg=require("../assets/ikony/black/"+dataJson.data.tlakTendImg);
            this.moonPhaseImg=require("../assets/ikony/black/"+dataJson.data.moonPhaseImg);
            this.slnkoImg=require("../assets/ikony/black/"+dataJson.data.slnkoImg)

            this.$emit("loaded","aktual")
            
        },
        emitInterface(){
            this.$emit("updateInterface",{id: "aktual",updateInterface: this.updateMe})
        }
    }
}
</script>

<style>

.aktualne{
    padding: 0 15px;
}

/*         MAIN ITEMS         */

.aktualne .infoItem{
    grid-column: span 12;
    font-weight: 300;
    margin: 0 calc(1vw + 10px);
    padding: 22px 0;
    border-bottom: 1px solid rgb(230, 230, 230);
    align-self: start;
}

.aktualne .infoItem.extend{
    grid-row: span 5;
}

.aktualne .infoItem.extend .label{
    font-weight: 900;
}

.aktualne .infoItem .ikona{
    height: 1.2em;
    vertical-align: middle;
    padding-right: 3px;
}

.aktualne .infoItem .label{
    text-transform: uppercase;
    grid-column: span 7;
    align-self: center;
    font-size: 1.1em;
    font-weight: 500;
}

.aktualne .infoItem .data{
    grid-column: span 5;
    font-weight: 700;
    justify-self: right;
    align-self: center;
    font-size: 1.8em;
    color: var(--textColor3);
}

.aktualne .infoItem .ikonaChart{
    height: 0.8em;
    vertical-align: middle;
    padding-left: 3px;
}

.aktualne .infoItem .ikonaTlak{
    height: 0.6em;
    vertical-align: middle;
}

.aktualne .infoItem:nth-last-child(2){
    border-bottom: 0;
}

.aktualne .infoItem .maximum{
    grid-column: 7/13;
    justify-self: right;
    font-size: 0.75em;
    color: var(--textColor3);
    font-weight: 100;
    margin-top: 2px;
}

.aktualne .infoItem .minimum{
    grid-column: 1/7;
    font-size: 0.75em;
    color: var(--textColor3);
    font-weight: 100;
    margin-top: 2px;
}

.aktualne .infoItem.small .label{
    grid-column: span 6;
}

.aktualne .infoItem.small .data{
    grid-column: span 6;
}

.aktualne .infoItem .label.clickable{
    cursor: pointer;
}

/*        SUHRN              */
.aktualne .infoSuhrn{
    grid-column: span 12;
    font-size: 1em;
    margin: 10px 0;
    text-align: left;
}

.aktualne .infoItem.suhrn{
    border-bottom: 1px solid var(--color2);
}


/*          GRAFY           */
.skrytyGraf{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: span 12;
    overflow: hidden;
    max-height: 0;
    transition: all 0.5s;
    height: calc(150px + 15vh);
}

.skrytyGraf.extend{
    max-height: calc(150px + 15vh);
}

.highChartsGraf{
    height: calc(150px + 15vh);
    width: 100%;
    align-self: end;
    margin-bottom: -15px;
}


/*          SLNKO MESIAC          */


.aktualne .infoItem.slnkoMesiac{
    height: 100%;
    padding: 0;
}

.aktualne .infoItem .slnkoMesiacData.right{
    justify-self: right;
}

.aktualne .infoItem .slnkoMesiacData{
    grid-column: span 3;
    grid-row: span 2;
    font-weight: 700;
    font-size: 1.8em;
    color: var(--textColor3);
    justify-self: right;
    display: none;
}

.aktualne .infoItem .slnkoMesiacData .popis{
    font-size: 0.5em;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: 500;

}

.aktualne .infoItem .slnkoMesiacData img{
    height: 1.5em;
    vertical-align: middle;
    margin-right: 5px;
}


/*            UPDATED             */

.aktualne .update{
    grid-column: span 12;
    justify-self: center;
    color: var(--textColor3);
    font-size: 0.85em;
    font-weight: 100;
    margin-top: 10px;
}

.aktualne .update .ikona{
    height: 1em;
    vertical-align:middle;
}



@media screen and (min-width: 620px) {
    .aktualne .infoItem{
        grid-column: span 6;
        padding: 25px 0;
    }

    .aktualne .infoItem .minimum,
    .aktualne .infoItem .maximum{
        font-weight: 300;
    }

    .aktualne .infoItem:nth-last-child(3):nth-child(even){
        border-bottom: 0;
    }

    .aktualne .infoItem.suhrn{
        grid-column: span 12;
    }

    .aktualne .infoItem.suhrn .label{
        grid-column: span 6;
        grid-row: 1;
        margin-bottom: 10px;
    }

    .aktualne .infoItem.suhrn .infoSuhrn{
        grid-column: span 6;
        grid-row: 2;
        margin: 0;
    }

    .aktualne .infoItem.suhrn .minimum{
        display: none;
    }

    .aktualne .infoItem.suhrn .maximum{
        display: none;
    }

    .aktualne .infoItem .slnkoMesiacData{
        display: block;
    }
}

</style>