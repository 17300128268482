<template>
  <div class="bouncer">
      <div></div>
      <div></div>
      <div></div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.bouncer{
    display: flex;
    justify-content: space-around;
    width: 30px;
    height: 20px;
    align-items: flex-end;
}

.bouncer div{
    height: 7px;
    width: 7px;
    border-radius: 50%;
    background-color: var(--color1);
    animation: bounce 0.5s cubic-bezier(.19,.57,.3,.98) infinite alternate;
}

.bouncer div:nth-child(2){
    animation-delay: .1s;
    opacity: .9;
}
.bouncer div:nth-child(3){
    animation-delay: .2s;
    opacity: .8;
}

@keyframes bounce {
    from{transform: translateY(0px);}
    to{transform: translateY(-12px);}
}
</style>