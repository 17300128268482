<template>
  <div id="progressBarContainer">
    <div id="progressBar"></div>
  </div>
  <div class="offline" v-if="dataOffline!=null">
    NEAKTUÁLNE DÁTA - Stanica je pravdepodobne dočasne offline. Posledné aktuálne dáta: {{dataOffline}}.
  </div>
  <div class="help grid" v-if="helpItem>=0">
    <div class="helpContent grid">
      <div class="title">
        Ako na to...
      </div>
      <div class="helpItem uvod">
        Vitajte v novej webovej aplikácií MeteoBB. Keďže sme pridali niekoľko nových funkcií, pripravili sme pre vás krátky návod ako s novým webom pracovať.
      </div>
      <div class="itemTitle" v-show="helpItem==0">
        Navigácia
      </div>
      <div class="helpItem grid" v-show="helpItem==0">
        <div class="text">
          Web je po novom rozdelený na štyri sekcie. Dáta v každej sekcii sa týkajú len zvolenej lokality:
          <p><b>Aktuálne</b> - základné informácie a grafy</p>
          <p><b>Podrobnosti</b> - všetky ostatné podrobnosti a ostatné údaje</p>
          <p><b>Archív a dáta</b> - rekordné merania na danej stanici a archív dát</p>
          <p><b>O nás</b> - informácie a kontakt</p>
        </div>
      </div>
      <div class="itemTitle" v-show="helpItem==1">
        Výber lokality
      </div>
      <div class="helpItem grid" v-show="helpItem==1">
        <div class="text">
          Výber lokality, pre ktorú sa zobrazujú všetky údaje, nájdete pod logom "MeteoBB".
        </div>
        <img class="imgLarge" src="./assets/help/lokality.jpg" alt="">
      </div>
      <div class="itemTitle" v-show="helpItem==2">
        Zobrazenie grafu
      </div>
      <div class="helpItem grid" v-show="helpItem==2">
        <div class="text">
          Ak je pri názve hodnoty zobrazená malá ikonka grafu, po kliknutí na daný názov sa zobrazí dodatočný graf.
        </div>
        <img class="imgLarge" src="./assets/help/grafy.jpg" alt="">
      </div>
      <div class="itemTitle" v-show="helpItem==3">
        Pohyb po grafe
      </div>
      <div class="helpItem grid" v-show="helpItem==3">
        <div class="text">
          Ak po grafe prejdete myškou alebo na dotykových displejoch prstom, na grafe sa vám zobrazia aktuálne údaje v danom bode.
        </div>
        <img class="imgLarge" src="./assets/help/tooltip.jpg" alt="">
      </div>
      <div class="itemTitle" v-show="helpItem==4">
        Archív
      </div>
      <div class="helpItem grid" v-show="helpItem==4">
        <div class="text">
          V sekcii "Archív a dáta" si okrem najvyšších nameraných hodnôt možete zobraziť akékoľvek dáta z histórie meraní. Stačí v kalendári zvoliť rozsah dátumov (od - do). Maximálny rozsah je 1 rok.
        </div>
        <img class="imgLarge" src="./assets/help/archiv.jpg" alt="">
      </div>
      <div class="itemTitle" v-show="helpItem==5">
        Dotykové funkcie
      </div>
      <div class="helpItem grid" v-show="helpItem==5">
        <div class="text">
          Web je teraz omnoho viac optimalizovaný pre mobilné telefóny a s tým suvisia aj ďalšie funkcie. Rýchlym pohybom prsta (swipe) doprava alebo doľava sa prepínate medzi jednotlivými sekciami. Swipe z hornej časti obrazovky smerom dole aktualizuje dáta na webe. 
        </div>
        <img class="imgLarge" src="./assets/help/touch.jpg" alt="">
      </div>
      <div class="itemTitle" v-show="helpItem==6">
        MeteoBB
      </div>
      <div class="helpItem grid" v-show="helpItem==6">
        <div class="text">
          Našli ste chybu na webe alebo máte pre nás pripomienku? Napíšte nám cez kontaktný formulár v sekcii "O nás". Ďakujeme.
        </div>
      </div>
      <div class="buttons grid">
        <div class="helpButton prev" v-show="helpItem>0" @click="helpPrev">
          <img src="./assets/ikony/white/back.png" alt="">
          <span>Späť</span>
        </div>
        <div class="helpButton next" v-show="helpItem<6" @click="helpNext">
          <span>Ďalej</span>
          <img src="./assets/ikony/white/next.png" alt="">
        </div>
        <div class="helpButton next" v-show="helpItem==6" @click="helpClose">
          <span>Zavrieť</span>
        </div>
      </div>
    </div>
  </div>
  <div
    class="loadDiv"
    :class="{
      showed: refreshState.showing,
      transitioned: refreshState.transitioning,
    }"
  >
    <img
      class="loadIcon"
      :src="refreshState.images[refreshState.imgId]"
      alt=""
      :class="{ animated: refreshState.animating }"
    />
  </div>
  <div
    class="bottomMenu"
    @click="showOffsets"
    :class="{ menuShowed: items[currentIndex] == 'podrobne' }"
  >
    <span
      v-for="(item, index) in scrollArrayItems"
      :key="item.id"
      @click="scrollToItem(index)"
    >
      <img
        v-show="index == scrollItemCurrent"
        :src="podrobnosti[item.id].img2"
        alt=""
      />
      <img
        v-show="index != scrollItemCurrent"
        :src="podrobnosti[item.id].img"
        alt=""
      />
    </span>
  </div>

  <div class="mainContainer">
    <!-- <div class="headerWrapper"> -->
    <div class="header" id="headerSwipe">
      <div class="headerBG">
        <div class="BG_image" id="BG_image_x"></div>
        <div
          v-for="(image, index) in bgImages"
          :key="index"
          :id="'BG_image_' + index"
          class="BG_image"
          :class="{
            bgImageActual: index === bgImageActual,
            bgImagePrev: index === bgImagePrev,
          }"
          :style="{ backgroundImage: 'url(' + image + ')' }"
        ></div>
      </div>
      <Header
        @update="loadCustom"
        @lokalitaChanged="lokalitaChanged"
        @loaded="loaded"
        @updateInterface="getInterface"
        :lokalita="aktualnaLokalita"
      />
    </div>
    <!-- </div> -->
    <div class="contentWrapper">
      <div class="contentMenu grid">
        <span
          class="menuItem"
          @click="goTo(0)"
          :class="{ selected: items[currentIndex] === 'aktual' }"
          >Aktuálne</span
        >
        <span
          class="menuItem"
          @click="goTo(1)"
          :class="{ selected: items[currentIndex] === 'podrobne' }"
          >Podrobnosti</span
        >
        <span
          class="menuItem"
          @click="goTo(2)"
          :class="{ selected: items[currentIndex] === 'archiv' }"
          >Archív a dáta</span
        >
        <span
          class="menuItem"
          @click="goTo(3)"
          :class="{ selected: items[currentIndex] === 'info' }"
          >O nás</span
        >
      </div>

      <div v-show="infoBoxes.length" class="infoBoxItems">
        <div
          v-for="(infoBox, index) in infoBoxes"
          :key="index"
          class="infoBox"
        >
          <div class="infoBoxBar" 
            :class="{
              color1: infoBox.color === '1',
              color2: infoBox.color === '2',
              color3: infoBox.color === '3',
            }">
          </div>
          <div class="infoBoxContentWrapper">
            <div class="infoBoxContent grid">
              <div class="infoBoxTitle">{{ infoBox.title }}</div>
              <img class="closeImg" src="./assets/ikony/black/close.png" alt="" @click="closeInfoBox(index)" />
              <div class="infoBoxText">{{ infoBox.text }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="content" id="touch-container">
        <div
          id="rendered-items-flexbox"
          :class="transitionClass"
          :style="{ transform: transformStyle }"
        >
          <div
            v-for="item in renderedItems"
            :id="item.id"
            :key="item.key"
            class="rendered-item"
            v-show="item.rendered"
          >
            <Aktual
              @updateHeight="updateHeight"
              @loaded="loaded"
              @updateInterface="getInterface"
              v-if="item.id === 'aktual'"
            />
            <Podrobne
              @scrollArray="scrollArray"
              @updateHeight="updateHeight"
              @loaded="loaded"
              @updateInterface="getInterface"
              v-else-if="item.id === 'podrobne'"
            />
            <Archiv
              @updateHeight="updateHeight"
              @loaded="loaded"
              @updateInterface="getInterface"
              v-else-if="item.id === 'archiv'"
            />
            <Info
              @loaded="loaded"
              @updateInterface="getInterface"
              v-else-if="item.id === 'info'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Aktual from "./components/Aktual.vue";
import Podrobne from "./components/Podrobne.vue";
import Archiv from "./components/Archiv.vue";
import Info from "./components/Info.vue";
import Hammer from "./hammer.min.js";
import _ from "lodash";
import Moment from "moment";
import MomentTimeZone from "moment-timezone";

import { Chart, registerables, Interaction } from "chart.js";
//import {CrosshairPlugin,Interpolate} from 'chartjs-plugin-crosshair';
import "chartjs-adapter-date-fns";
import Highcharts from "highcharts";

Chart.register(...registerables);
//Chart.register(CrosshairPlugin);
//Interaction.modes.interpolate = Interpolate;

Chart.defaults.font.family = "'Roboto Slab', serif";
Chart.defaults.font.weight = 300;

Highcharts.setOptions({
  lang: {
    decimalPoint: ",",
    months: [
      "Január",
      "Február",
      "Marec",
      "Apríl",
      "Máj",
      "Jún",
      "Júl",
      "August",
      "September",
      "Október",
      "November",
      "December",
    ],
    shortMonths: [
      "Jan.",
      "Feb.",
      "Mar.",
      "Apr.",
      "Máj",
      "Jún",
      "Júl",
      "Aug.",
      "Sep.",
      "Okt.",
      "Nov.",
      "Dec.",
    ],
    weekdays: [
      "Nedeľa",
      "Pondelok",
      "Utorok",
      "Streda",
      "Štvrtok",
      "Piatok",
      "Sobota",
    ],
  },
  chart: {
    type: "spline",
    style: {
      fontFamily: "'Roboto Slab', serif",
      fontWeight: 300,
    },
    marginLeft: 5,
    marginRight: 5,
  },
  tooltip: {
    backgroundColor: "rgba(0,0,0,0.8)",
    borderWidth: 0,
    borderRadius: 0,
    shadow: false,
    style: {
      color: "#fff",
      fontSize: "1em",
    },
    dateTimeLabelFormats: {
      day: "%A, %e. %b %Y, %H:%M",
      hour: "%A, %e. %b, %H:%M",
      millisecond: "%H:%M:%S.%L",
      minute: "%A, %e. %b, %H:%M",
      month: "%B %Y",
      second: "%A, %e. %b, %H:%M",
      week: "Týždeň od %e. %b %Y",
      year: "%Y",
    },
    shared: true,
    positioner: (labelWidth, labelHeight, point) => {
      var x = 0;
      var y = 0;
      if (point.plotX - labelWidth / 2 < 0) x = point.plotX + 8;
      else if (point.plotX - labelWidth < 0) x = point.plotX - labelWidth / 2;
      else x = point.plotX - labelWidth;
      if (point.plotY - labelHeight < 0) y = 0;
      else y = point.plotY - labelHeight;

      return { x: Math.round(x), y: y };
    },
  },
  title: {
    text: "",
  },
  plotOptions: {
    series: {
      color: "rgb(0,129,197)",
      states: {
        select: {
          enabled: false,
        },
        hover: {
          lineWidthPlus: 0,
        },
      },
      marker: {
        symbol: "circle",
      },
    },
    spline: {
      lineWidth: 1,
      marker: {
        radius: 0,
      },
    },
  },
  time: {
    moment: Moment,
    // timezone: "Europe/Prague"
    useUTC: false,
  },
  xAxis: {
    gridLineColor: "#F3F3F3",
    gridLineDashStyle: "Dash",
    gridLineWidth: 1,
    crosshair: {
      color: "var(--color2)",
    },
    tickPixelInterval: 100,
    startOnTick: false,
    labels: {
      autoRotationLimit: 0,
    },
    lineColor: "#F0F0F0",
    tickColor: "#F0F0F0",
  },
  yAxis: {
    allowDecimals: false,
    alignTicks: true,
    gridLineColor: "#F3F3F3",
    gridLineWidth: 1,
    tickPixelInterval: 50,
    title: {
      align: "high",
      rotation: 0,
      offset: 20,
      y: -15,
      style: {
        fontWeight: 500,
      },
      text: "",
      textAlign: "center",
    },
    labels: {
      style: {
        fontWeight: 500,
      },
      x: 0,
      y: -2,
    },
  },
  responsive: {
    rules: [
      {
        chartOptions: {
          xAxis: {
            tickPixelInterval: 70,
          },
        },
        condition: {
          maxWidth: 600,
        },
      },
    ],
  },
});

export default {
  components: { Header, Aktual, Podrobne, Archiv, Info },

  data() {
    return {
      infoBoxes: [],
      podrobnosti: [
        {
          name: "Oblačnosť",
          img: require("./assets/ikony/white/oblakyZrazky.png"),
          img2: require("./assets/ikony/black/oblakyZrazky.png"),
        },
        {
          name: "Vietor",
          img: require("./assets/ikony/white/vietor.png"),
          img2: require("./assets/ikony/black/vietor.png"),
        },
        {
          name: "Slnko",
          img: require("./assets/ikony/white/svetlo.png"),
          img2: require("./assets/ikony/black/svetlo.png"),
        },
        {
          name: "Ovzdušie",
          img: require("./assets/ikony/white/PM10.png"),
          img2: require("./assets/ikony/black/PM10.png"),
        },
      ],
      items: ["aktual", "podrobne", "archiv", "info"], // IDs for all items
      currentIndex: 0,
      upcomingIndex: 0,
      transformStyle: "translateX(0)",
      transitionClass: "transition-initial",
      isTransitioning: false,
      updateInterfaces: {},
      headerLokalitaChange: null,
      lastUpdated: {},
      updateTimer: null,
      maxUpdateTime: 120000,
      scrollBarSize: 0,
      totalPageHeight: 0,
      helpItem:-1,
      dataOffline:0,

      bgImages: [],
      bgImageActual: 0,
      bgImagePrev: null,
      bgImageTimer: null,
      bgImageDurr: 20000,

      currentLoadingList: [],
      currentLoading: null,

      aktualnaLokalita: 1,

      scrollArrayItems: null,
      scrollItemCurrent: -1,

      refreshState: {
        animating: false,
        showing: false,
        transitioning: false,
        moving: false,
        refreshing: false,
        images: [
          require("./assets/ikony/updateIcon.png"),
          require("./assets/ikony/refreshIcon.png"),
        ],
        imgId: 0,
        loading: false,
      },
    };
  },

  computed: {
    infoItems() {
      let arr = [...this.items];
      if (arr.length === 2) {
        arr = [...arr, ...arr];
      }

      return arr.map((id, index) => ({
        id,
        key: `${id}-${index}`,
        rendered: false,
      }));
    },

    renderedItems() {
      const { currentIndex: i, infoItems } = this;
      const count = infoItems.length;
      if (count === 1) {
        return [infoItems[0]];
      }

      const lastIndex = infoItems.length - 1;

      var orderedItems = [];

      if (i === 0) {
        orderedItems[0] = infoItems[lastIndex];
        for (var x = 1; x <= lastIndex; x++) orderedItems[x] = infoItems[x - 1];
      } else if (i === lastIndex) {
        orderedItems[0] = infoItems[i - 1];
        orderedItems[1] = infoItems[i];
        for (var x = 0; x < i - 1; x++) orderedItems[x + 2] = infoItems[x];
      } else {
        var y = 0;
        for (var x = i - 1; x <= lastIndex; x++, y++)
          orderedItems[y] = infoItems[x];
        for (var x = 0; x < i - 1; x++, y++) orderedItems[y] = infoItems[x];
      }
      for (var x = 0; x < 3; x++) orderedItems[x].rendered = true;
      for (var x = 3; x <= lastIndex; x++) orderedItems[x].rendered = false;

      return orderedItems;
    },

    isNextAvailable() {
      const { items, currentIndex } = this;
      return currentIndex < items.length - 1;
    },

    isPreviousAvailable() {
      const { items, currentIndex } = this;
      return currentIndex > 0;
    },

    allUpdateInterfaces() {
      const allInterfaces = [
        this.updateInterfaces.header,
        this.updateInterfaces[this.items[this.currentIndex]],
      ];

      for (const _interface in this.updateInterfaces) {
        if (
          _interface != "header" &&
          _interface != this.items[this.currentIndex]
        ) {
          allInterfaces.push(this.updateInterfaces[_interface]);
        }
      }
      return allInterfaces;
    },
  },

  watch: {
    renderedItems() {
      //console.log("renderedChanged")
      this.updateHeight();
    },
  },

  methods: {
    helpPrev(){
      if(this.helpItem>0)this.helpItem--;
    },
    helpNext(){
      if(this.helpItem<6)this.helpItem++;
    },
    helpClose(){
      this.helpItem=-1;
      this.$cookies.set("meteobb_help", true, "10Y");
    },

    scrollToItem(i) {
      $(".mainContainer").animate(
        { scrollTop: this.scrollArrayItems[i].dom.offsetTop + 320 },
        500
      );
    },

    scrollArray(items) {
      //console.log(items)
      this.scrollArrayItems = items;
    },

    showOffsets() {
      this.scrollArrayItems.forEach((item) => {
        //console.log(item.dom.offsetTop);
      });
    },

    resetTimer() {
      clearInterval(this.updateTimer);
      this.updateTimer = setInterval(() => {
        this.loadCustom();
      }, this.maxUpdateTime);
    },

    async getImages() {
      const data = await fetch(
        "PHP/loadBG.php?lokalita=" + this.aktualnaLokalita
      );
      const dataJson = await data.json();
      const randomData = dataJson["obrazky"].sort(() => Math.random() - 0.5);
      //console.log(randomData);
      this.bgImages = [];
      if (this.bgImageTimer != null) clearInterval(this.bgImageTimer);
      randomData.forEach((obrazok, index) => {
        var img = new Image();
        img.onload = (e) => {
          this.bgImages.push(e.target.getAttribute("src"));
          if (index == randomData.length - 1) {
            $("#BG_image_x").fadeOut(2000);
            this.bgImageTimer = setInterval(() => {
              this.rotateImage();
            }, this.bgImageDurr);
          }
        };
        img.src = require("./assets/BG/" + obrazok);
      });
    },

    rotateImage() {
      const count = this.bgImages.length;
      if (count == 0) return;

      this.bgImagePrev = this.bgImageActual;

      var next;
      if (this.bgImageActual == count - 1) next = 0;
      else next = this.bgImageActual + 1;

      const nextImg = $("#BG_image_" + next);
      const currentImg = $("#BG_image" + this.bgImageActual);

      nextImg.fadeOut(0);
      this.bgImagePrev = this.bgImageActual;
      this.bgImageActual = next;
      nextImg.fadeIn(5000);
    },

    loadData() {
      if (this.currentLoading === null) return;
      this.currentLoadingList[this.currentLoading](this.aktualnaLokalita);
    },

    loadSingle(index) {
      if (this.currentLoading === null) {
        this.loadingIcon(true);
        this.currentLoadingList = [];
        this.currentLoadingList[0] = this.updateInterfaces[this.items[index]];
        this.currentLoading = 0;
        this.loadData();
      } else {
        this.currentLoadingList.push(this.updateInterfaces[this.items[index]]);
      }
    },

    loadAll() {
      if (this.refreshState.loading) return;
      //console.log("LOAD ALL")

      this.currentLoadingList = [];
      this.currentLoadingList = this.allUpdateInterfaces;
      this.currentLoading = 0;
      this.loadingIcon(true);
      this.loadData();
    },
    loadCustom() {
      //console.log("LOAD CUSTOM START "+this.refreshState.loading);
      if (this.refreshState.loading) return;
      //console.log("LOAD CUSTOM")

      this.currentLoadingList = [
        this.updateInterfaces.header,
        this.updateInterfaces[this.items[this.currentIndex]],
      ];
      this.currentLoading = 0;

      this.loadingIcon(true);
      this.loadData();

    },

    loadingIcon(on) {
      if (on) {
        //console.log("ICON_ON");
        if (this.refreshState.loading) return;
        this.refreshState.loading = true;
        this.refreshState.imgId = 0;
        this.refreshState.transitioning = true;
        this.refreshState.animating = true;
        this.refreshState.showing = true;
      } else {
        //console.log("ICON_OFF");
        this.refreshState.showing = false;
        this.refreshState.loading = false;
        $(".loadDiv").on("transitionend", () => {
          //console.log("ICON_TRANS_END");
          this.refreshState.transitioning = false;
          this.refreshState.animating = false;
          $(".loadDiv").off("transitionend");
        });
      }
    },

    loaded(item) {
      this.lastUpdated[item] = Date.now();
      this.updateHeight();
      if (this.currentLoading === null) {
        this.loadingIcon(false);
        return;
      }
      if (this.currentLoading < this.currentLoadingList.length - 1) {
        this.currentLoading++;
        this.loadData();
      } else {
        //this.resetTimer();
        this.currentLoading = null;
        this.getInfoBoxes();
        this.loadingIcon(false);
      }
    },

    lokalitaChanged(novaLokalita) {
      if (this.refreshState.loading) return;
      if (novaLokalita == this.aktualnaLokalita) return;

      if (this.headerLokalitaChange != null)
        this.headerLokalitaChange(novaLokalita);

      this.aktualnaLokalita = novaLokalita;
      this.$cookies.set("meteobb_lokalita", this.aktualnaLokalita, "1Y");
      $("#BG_image_x").fadeIn(0);
      this.getImages();
      this.getInfoBoxes();
      this.loadAll();
    },

    getInterface(childInterface) {
      this.updateInterfaces[childInterface.id] = childInterface.updateInterface;
      if (childInterface.id == "header")
        this.headerLokalitaChange = childInterface.lokalitaChanged;
    },

    goTo(index) {
      this.currentIndex = this.upcomingIndex = index;
      const lastUpdatet = this.lastUpdated[this.items[this.currentIndex]];
      if (lastUpdatet === null || Date.now() - lastUpdatet > this.maxUpdateTime)
        this.loadSingle(this.currentIndex);
    },

    handleRefresh(e) {
      var moveDist;

      if (this.refreshState.loading) {
        //PREBIEHA
        return;
      }

      if (e.deltaY < 0 && !this.refreshState.refreshing) {
        //OVER
        return;
      }

      if (!this.refreshState.moving) {
        //START
        this.refreshState.moving = true;
        return;
      }

      if (e.isFinal) {
        //END

        this.refreshState.transitioning = true;
        $(".loadDiv").on("transitionend", () => {
          this.refreshState.transitioning = false;
          $(".loadDiv").off("transitionend");
          this.refreshState.imgId = 0;

          if (this.refreshState.refreshing) {
            this.refreshState.animating = true;
            this.loadCustom();
          }

          this.refreshState.moving = false;
          this.refreshState.refreshing = false;
        });
        $(".loadDiv").css({
          "-webkit-transform": "translateY(0px)",
          transform: "translateY(0px)",
        });
        if (this.refreshState.refreshing) {
          this.refreshState.showing = true;
        } else {
          this.refreshState.showing = false;
        }
        return;
      }

      if (e.deltaY < 200 && !this.refreshState.refreshing) {
        //MOVING
        moveDist = e.deltaY / 2;
        $(".loadDiv").css({
          "-webkit-transform": "translateY(" + moveDist + "px)",
          transform: "translateY(" + moveDist + "px)",
        });
        $(".loadIcon").css({
          "-webkit-transform": "rotate(" + e.deltaY + "deg)",
          transform: "rotate(" + e.deltaY + "deg)",
        });
        return;
      }

      if (!this.refreshState.refreshing) {
        this.refreshState.refreshing = true;
        this.refreshState.imgId = 1;
        $(".loadIcon").css({
          "-webkit-transform": "rotate(0deg)",
          transform: "rotate(0deg)",
        });
      }
      if (e.deltaY >= 0) {
        moveDist = 100 + (e.deltaY - 200) / 8;
        $(".loadDiv").css({
          "-webkit-transform": "translateY(" + moveDist + "px)",
          transform: "translateY(" + moveDist + "px)",
        });
      }
    },
    handleTouchEvents(e) {
      //console.log(e)
      const { isTransitioning, isPreviousAvailable, isNextAvailable } = this;

      if (isTransitioning) {
        return;
      }

      if (e.direction == 2 && isNextAvailable) this.next();
      else if (e.direction == 4 && isPreviousAvailable) this.previous();
    },

    previous: _.debounce(
      function () {
        if (this.isTransitioning) {
          return;
        }

        if (!this.isPreviousAvailable) {
          return;
        }

        const { currentIndex, items } = this;
        const currentWidth = $("#rendered-items-flexbox").width();

        this.transitionClass = "transition-item";
        this.transformStyle = "translateX(" + currentWidth + "px)";

        const prevIndex =
          currentIndex === 0 ? items.length - 1 : currentIndex - 1;
        this.upcomingIndex = prevIndex;
      },
      100,
      { leading: true, trailing: false }
    ),

    next: _.debounce(
      function () {
        if (this.isTransitioning) {
          return;
        }

        if (!this.isNextAvailable) {
          return;
        }

        const { currentIndex, items } = this;
        const currentWidth = $("#rendered-items-flexbox").width();

        this.transitionClass = "transition-item";
        this.transformStyle = "translateX(-" + currentWidth + "px)";

        const nextIndex =
          currentIndex === items.length - 1 ? 0 : currentIndex + 1;
        this.upcomingIndex = nextIndex;
      },
      100,
      { leading: true, trailing: false }
    ),

    updateCurrentItem() {
      this.currentIndex = this.upcomingIndex;
      const lastUpdatet = this.lastUpdated[this.items[this.currentIndex]];
      if (lastUpdatet === null || Date.now() - lastUpdatet > this.maxUpdateTime)
        this.loadSingle(this.currentIndex);
      this.resetTranslate();
      this.updateHeight();
    },

    resetTranslate() {
      this.isTransitioning = false;
      this.transitionClass = "transition-initial";
      this.transformStyle = "translateX(0)";
    },

    updateScrollBar() {
      this.totalPageHeight =
        $(".mainContainer").prop("scrollHeight") - window.innerHeight;
      this.scrollBarSize =
        (window.innerHeight / $(".mainContainer").prop("scrollHeight")) *
        window.innerHeight;
      const newProgressHeight =
        ($(".mainContainer").scrollTop() / this.totalPageHeight) *
        (window.innerHeight - this.scrollBarSize);

      // console.log("totalPageHeight: "+this.totalPageHeight)
      // console.log("scrollBarSize: "+this.scrollBarSize)
      // console.log("newProgressHeight: "+newProgressHeight)

      $("#progressBar").height(this.scrollBarSize.toFixed());
      $("#progressBar").css("top", newProgressHeight.toFixed() + "px");
    },

    updateElementsHeight(currentDiv) {
      this.renderedItems.forEach((item) => {
        if (item.id != this.items[this.currentIndex]) {
          $("#" + item.id).css("height", currentDiv.height() + "px");
        }
      });
      this.updateScrollBar();
    },

    async updateHeight() {
      for (var i = 0; i < this.renderedItems.length; i++) {
        if (
          this.renderedItems[i].rendered &&
          !$("#" + this.renderedItems[i].id).is(":visible")
        ) {
          setTimeout(() => {
            this.updateHeight();
          }, 10);
          return;
        }
        //console.log(this.renderedItems[i].id+": "+$("#"+this.renderedItems[i].id).is(":visible")+" ("+this.renderedItems[i].rendered+")")
      }
      const currentDiv = $("#" + this.items[this.currentIndex]);
      currentDiv.css("height", "100%");

      const scroll = $(".mainContainer").scrollTop();
      const maxScroll =
        $(".mainContainer").prop("scrollHeight") - window.innerHeight;
      var newMaxScroll = Math.floor(
        maxScroll -
          ($("#rendered-items-flexbox").height() - currentDiv.height()) -
          60
      );
      if (newMaxScroll < 0) newMaxScroll = 0;

      if (scroll > newMaxScroll) {
        //$(".mainContainer").scrollTop(newMaxScroll)
        $(".mainContainer").animate(
          {
            scrollTop: newMaxScroll,
          },
          250,
          () => {
            this.updateElementsHeight(currentDiv);
          }
        );
      } else {
        this.updateElementsHeight(currentDiv);
      }
    },

    async getInfoBoxes() {
      const data = await fetch("https://meteobb.sk/PHP/loadInfoBoxes.php?lokalita=" + this.aktualnaLokalita);
      const dataJson = await data.json();

      var infoBoxes=[];
      if(dataJson.dataOffline==1){
        this.dataOffline=dataJson.lastData;
      }else this.dataOffline=null;

      if (this.$cookies.isKey("meteobb_infoBoxes") && dataJson.infoBoxes.length) {
        const cookieBoxes = this.$cookies.get("meteobb_infoBoxes");
        //console.log(cookieBoxes);
        for (const infoBox of dataJson.infoBoxes) {
          var show=1;
          for (const cookieBox of cookieBoxes.id) {
            if (cookieBox == this.aktualnaLokalita+"_"+infoBox.id) {
              show=0;
              break;
            }
          }
          if(show)infoBoxes.push(infoBox);
        }
        
      } else infoBoxes=dataJson.infoBoxes;
      this.infoBoxes=infoBoxes;

    },

    closeInfoBox(index){
      if (this.$cookies.isKey("meteobb_infoBoxes")) {
        const cookieBoxes = this.$cookies.get("meteobb_infoBoxes");
        cookieBoxes.id.push(this.aktualnaLokalita+"_"+this.infoBoxes[index].id);
        this.$cookies.set("meteobb_infoBoxes",cookieBoxes,"10Y");
      }else{
        const cookieBoxes={id:[this.aktualnaLokalita+"_"+this.infoBoxes[index].id]};
        this.$cookies.set("meteobb_infoBoxes",cookieBoxes,"10Y");
      }
      this.getInfoBoxes();
    }
  },

  created() {
  /*
    if(!this.$cookies.isKey("meteobb_help")){
      this.helpItem=0;
    }else{
      this.helpItem=-1;
    }
*/

    if (!this.$cookies.isKey("meteobb_lokalita")) {
      this.$cookies.set("meteobb_lokalita", this.aktualnaLokalita, "1Y");
    } else {
      this.aktualnaLokalita = parseInt(this.$cookies.get("meteobb_lokalita"));
    }
    this.getInfoBoxes();

    this.items.forEach((item) => {
      this.lastUpdated[item] = null;
    });

    this.getImages();
  },

  mounted() {
    const touchContainer = document.getElementById("touch-container");
    const headerSwipe = document.getElementById("headerSwipe");

    delete Hammer.defaults.cssProps.userSelect;

    //slide
    const hammer = new Hammer.Manager(touchContainer, {
      recognizers: [
        // [Hammer.Pan, { direction: Hammer.DIRECTION_HORIZONTAL }],
        [Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }],
      ],
      inputClass: Hammer.TouchInput,
    });

    //update
    const hammerRefresh = new Hammer.Manager(headerSwipe, {
      recognizers: [[Hammer.Pan, { direction: Hammer.DIRECTION_VERTICAL }]],
      inputClass: Hammer.TouchInput,
    });

    hammer.on("swipe", this.handleTouchEvents);
    hammerRefresh.on("pan", this.handleRefresh);

    const itemsContainer = document.getElementById("rendered-items-flexbox");

    itemsContainer.addEventListener("transitionstart", (e) => {
      if (e.target === itemsContainer) {
        this.isTransitioning = true;
      }
    });
    itemsContainer.addEventListener("transitionend", (e) => {
      if (e.target === itemsContainer) {
        this.updateCurrentItem();
      }
    });

    ///////////////////////// SCROLLSBAR ///////////////////////////////////////
    const progressBarContainer = document.querySelector(
      "#progressBarContainer"
    );
    const progressBar = document.querySelector("#progressBar");
    this.totalPageHeight =
      $(".mainContainer").prop("scrollHeight") - window.innerHeight;

    this.scrollBarSize =
      (window.innerHeight / $(".mainContainer").prop("scrollHeight")) *
      window.innerHeight;
    $("#progressBar").height(this.scrollBarSize.toFixed());

    let debounceResize;

    window.addEventListener(
      "scroll",
      () => {
        if ($(".mainContainer").scrollTop() == 0) window.scrollTo(0, 0);
        //console.log("scroll -> scrollBarSize: "+this.scrollBarSize);
        let newProgressHeight =
          ($(".mainContainer").scrollTop() / this.totalPageHeight) *
          (window.innerHeight - this.scrollBarSize);
        $("#progressBar").css("top", newProgressHeight.toFixed() + "px");

        if (this.items[this.currentIndex] == "podrobne") {
          const scroll = $(".mainContainer").scrollTop() + 200;
          this.scrollItemCurrent = -1;
          for (var i = this.scrollArrayItems.length - 1; i >= 0; i--) {
            if (this.scrollArrayItems[i].dom.offsetTop < scroll) {
              this.scrollItemCurrent = i;
              break;
            }
          }
        }
      },
      {
        capture: true,
        passive: true,
      }
    );

    window.addEventListener("resize", () => {
      clearTimeout(debounceResize);
      debounceResize = setTimeout(() => {
        this.totalPageHeight =
          $(".mainContainer").prop("scrollHeight") - window.innerHeight;
        this.scrollBarSize =
          (window.innerHeight / $(".mainContainer").prop("scrollHeight")) *
          window.innerHeight;
        let newProgressHeight =
          ($(".mainContainer").scrollTop() / this.totalPageHeight) *
          (window.innerHeight - this.scrollBarSize);
        $("#progressBar").height(this.scrollBarSize.toFixed());
        $("#progressBar").css("top", newProgressHeight.toFixed() + "px");
      }, 250);
    });

    this.updateHeight();

    this.updateTimer = setInterval(() => {
      //console.log("TIMER")
      this.loadCustom();
    }, this.maxUpdateTime);

    this.loadAll();
  },
};
</script>

<style>

.offline{
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 20px);
  background-color: rgba(213, 90, 90,0.8);
  z-index: 1000;
  color: white;
  font-size: 0.6em;
  padding: 10px;
  text-align: center;
  font-weight: 500;
}

.help{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.9);
  z-index: 1000;
  overflow-y: scroll;
  color: #fff;
}

@supports ((-webkit-backdrop-filter: blur(4px)) or (backdrop-filter: blur(4px))) {
  .help{
    background-color: rgba(0,0,0,0.6);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }
}

.helpContent{
  max-width: 600px;
  grid-column: span 12;
  justify-self: center;
  width: calc(100% - 40px);
  padding: 20px;
  grid-auto-rows: min-content !important;
}

.helpContent .title{
  grid-column: span 12;
  justify-self: center;
  font-size: 1.8em;
  font-weight: 700;
  align-self: start;
}

.helpContent .helpItem{
  grid-column: span 12;
  font-size: 0.7em;
  padding-bottom: 35px;
  padding-top: 20px;
  max-width: 400px;
  justify-self: center;
}

.helpContent .helpItem.uvod{
  max-width: 600px !important;
}

.helpContent .itemTitle{
  grid-column: span 12;
  justify-self: center;
  font-weight: 500;
}

.helpContent .imgLarge{
  grid-column: span 12;
  max-width: 80%;
  justify-self: center;
  margin: 10px 0;
}

.helpContent .text{
  grid-column: span 12;
  text-align: center;
}

.helpContent .buttons{
  grid-column: span 12;
  max-width: 350px;
  justify-self: center;
  width: 100%;
  padding: 0 20px;
}

.helpContent .helpButton{
  background-color: var(--color1);
  padding: 6px 10px;
  font-size: 0.6em;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
}

.helpContent .helpButton.prev{
  justify-self: left;
  grid-column: 1/7;
}

.helpContent .helpButton.next{
  justify-self: right;
  grid-column: 7/13;
}

.helpContent .helpButton img{
  height: 1em;
  vertical-align: middle;
}

.helpContent .helpButton.prev img{
  margin-right: 5px;
}

.helpContent .helpButton.next img{
  margin-left: 5px;
}

.helpContent .helpButton span{
  vertical-align: middle;
}

.infoBoxItems{
  max-width: 960px;
  margin: 0 auto;
  color: var(--textColor3);
}

.infoBox{
  margin: 10px;
  display: flex;
}

.infoBoxBar{
  width: 10px;
  display: inline-block;
}

.infoBoxContentWrapper{
  width: 100%;
}

.infoBoxContent{
  padding: 10px;
  border: 1px solid;
  border-left: none !important;
  border-color: rgba(220, 220, 220);
  background-color: rgba(250, 250, 250);
}

.infoBoxTitle{
  grid-column: 2/12;
  justify-self: center;
  font-weight: 500;
  font-size: 1.1em;
}

.infoBox .closeImg{
  width: 10px;
  grid-column: 12/13;
  justify-self: end;
  cursor: pointer;
}

.infoBoxText{
  grid-column: 2/12;
  justify-self: center;
  margin-top: 10px;
  font-size: 0.9em;
}

.color1{
  background-color: rgb(91, 161, 91);
}
.color2{
  background-color: rgb(211, 164, 67);
}
.color3{
  background-color: rgb(213, 90, 90);
}

/*         LOADER          */
.loader {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.loader img {
  height: 1.5em;
  align-self: center;
  margin: 3px;
}

.loader .loaderItem {
  align-self: center;
  margin: 3px;
  font-size: 1.2em;
  font-weight: 500;
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.transitioned {
  transition: all 0.25s ease;
}

.animated {
  -webkit-animation: loader 1s infinite linear !important;
  animation: loader 1s infinite linear !important;
}

.showed {
  -webkit-transform: translateY(60px) !important;
  transform: translateY(60px) !important;
}

.bottomMenu {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  bottom: -100px;
  z-index: 999;
  transition: all 0.25s ease;
  text-align: center;
  background-color: rgba(200, 200, 200, 0.9);
  border-radius: 5px;
  left: calc(50% - 125px);
  width: 250px;
}

.bottomMenu span {
  display: inline-block;
  height: 25px;
  cursor: pointer;
  margin: 0 5px;
}

.bottomMenu span img {
  height: 100%;
}

.menuShowed {
  bottom: 10px !important;
}

#rendered-items-flexbox {
  display: flex;
  justify-content: center;
  min-height: fit-content;
  width: 100%;
  box-sizing: border-box;
  touch-action: pan-y;
}

.transition-initial {
  transition: transform 0s ease;
}

.transition-item {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.rendered-item {
  height: 100%;
  overflow: hidden;
  min-width: 100%;
  width: 100%;
  box-sizing: border-box;
}

.item-content {
  height: 100%;
  width: 100vw;
  margin: 0 auto;
  box-sizing: border-box;
}

:root {
  --textColor1: rgb(32, 32, 32);
  --textColor3: rgb(63, 63, 63);
  --textColor2: rgb(177, 177, 177);
  --color1: rgb(0, 129, 197);
  --color2: rgb(171, 218, 243);
}

html {
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow: hidden;
  font-family: "Roboto Slab", serif;
  font-size: calc(2vw + 15px);
  font-weight: 300;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--textColor1);
}

.loadDiv {
  position: absolute;
  width: 40px;
  left: calc(50% - 20px);
  top: -50px;
  z-index: 999;
}

.loadIcon {
  width: 100%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: auto;
}

.mainContainer {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100vh;
  perspective: 1px;
  perspective-origin: top;
  transform-style: preserve-3d;
  font-size: 62.5%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* margin-top: 8vh; */
}

.mainContainer::-webkit-scrollbar {
  display: none;
}

.header {
  /* background: url("./assets/02.jpg") center center; */
  background-size: cover;
  transform: translateZ(-1px) scale(2.02);
  transform-origin: center top;
  height: 40vh;
  min-height: 150px;
  width: 100%;
  z-index: -2;
  color: white;
  transform-style: preserve-3d;
}

.headerBG {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -40;
}

#BG_image_x {
  background: url("./assets/01.jpg") center center;
  z-index: -5 !important;
}

.headerBG .BG_image {
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  z-index: -30;
}

.bgImageActual {
  z-index: -10 !important;
}

.bgImagePrev {
  z-index: -20 !important;
}

.contentWrapper {
  position: relative;
  width: 100%;
  background: white;
  box-shadow: 0px -11px 8px -10px #1116;
  -webkit-box-shadow: 0px -11px 8px -10px #1116;
  -moz-box-shadow: 0px -11px 8px -10px #1116;
  z-index: 1;
  padding-top: 10px;
}

.contentMenu {
  padding: 0 10px;
  max-width: 500px;
  margin: 0 auto;
  display: grid;
  /* grid-template-columns: repeat(14,1fr); */
  font-size: 0.8em;
  font-weight: 500;
  color: var(--textColor2);
}

.menuItem {
  grid-column: span 3;
  justify-self: center;
  /* align-self: center; */
  padding: 15px 0;
  cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
  .menuItem:hover {
    /* background-color: var(--color2); */
    border-bottom: 2px solid var(--color2);
  }
}

.contentMenu .selected {
  border-bottom: 2px solid var(--color1);
  color: var(--textColor1);
  font-weight: 900;
}

.content {
  max-width: 960px;
  padding: 10px 0 70px 0;
  margin: 0 auto;
  overflow: hidden;
}

#progressBarContainer {
  position: fixed;
  z-index: 10;
  background: var(--color2);
  width: 0.5%;
}

#progressBar {
  position: absolute;
  z-index: 20;
  background-color: var(--color1);
  width: 100%;
}

#progressBarContainer,
#progressBar {
  top: 0;
  right: 0;
  bottom: 0;
}

@media screen and (min-width: 620px) {
  body {
    font-size: calc(1vw + 15px);
  }
}

@media screen and (min-width: 960px) {
  body {
    font-size: 25px;
  }
}

@media (min-aspect-ratio: 5/4) {
  .header {
    height: 30vh;
  }
}
</style>
